import axios from "axios";
import { toast } from "react-toastify";

export class HttpService {
  constructor(setShowLoginPopup) {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.toast = toast;
    this.setShowLoginPopup = setShowLoginPopup;
  }

  async get(url, params = {}) {
    return this.axiosRequest("get", url, {}, params);
  }

  async post(url, data = {}, params = {}) {
    return this.axiosRequest("post", url, data, params);
  }

  async put(url, data = {}, params = {}) {
    return this.axiosRequest("put", url, data, params);
  }

  async delete(url, params = {}) {
    return this.axiosRequest("delete", url, {}, params);
  }

  async axiosRequest(method, url, data = {}, params = {}) {
    const token = localStorage.getItem("Login Token");
    const locationId = localStorage.getItem("locationId");
    const companyId = localStorage.getItem("companyId");

    if (token) {
      try {
        const response = await axios({
          method,
          url: `${this.baseUrl}${url}`,
          data,
          params,
          headers: {
            Authorization: token,
            location_id: locationId,
            company_id: companyId,
          },
        });
        return response?.data;
      } catch (error) {
        const errorMessage =
          typeof error?.response?.data?.message === "object" &&
          typeof error?.response?.data?.message?.message === "string"
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message ||
              error?.response?.data?.error?.message ||
              "Something went wrong";

        if (error.response) {
          if (
            errorMessage === "invalid token" ||
            errorMessage === "jwt expired" ||
            errorMessage === "invalid signature"
          ) {
            this.setShowLoginPopup(true);
            // localStorage.clear();
          } else {
            this.toast.error(errorMessage);
          }
        } else if (error.request) {
          this.toast.error("Request failed, please try again later");
        } else {
          this.toast.error("Something went wrong");
        }
        throw new Error(errorMessage);
      }
    } else {
      this.toast.error("Invalid Token");
    }
  }
}
