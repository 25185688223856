import React, { useEffect, useState } from "react";
import "./scheduleConflict.scss";
import { DataGrid } from "@mui/x-data-grid";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { useFilter } from "../../context/FilterProvider";
import { Box, gridClasses, styled } from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Encryption } from "../../Mixin/EncryptDecryptId";

const ScheduleConflict = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const { company_id, location_id } = useFilter();

  const fetchCoflictedSchedule = () => {
    setLoading(true);
    httpService
      .get("dashboard/conflicted")
      .then((response) => {
        if (response?.data?.length > 0) {
          const data = response?.data?.map((item, index) => {
            item.id = index;
            return item;
          });
          setRows(data);
        }
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching tasks:", error);
      });
  };

  useEffect(() => {
    fetchCoflictedSchedule();
  }, [showLoginPopup, company_id, location_id]);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="schedule">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Conflicted Schedules</h1>
          </div>
        </div>
        <div className="table-filter-wrap"></div>
        <div
          style={{
            height: `${rows?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rows?.length > 0 &&
              rows?.map((row, index) => ({
                ...row,
                id: row.id,
              }))
            }
            // getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "employee_name",
                headerName: "Employee Name",
                width: 200,
                flex: 1,
                minWidth: 200,
                sortable: true,
              },
              {
                field: "schedule_name_1",
                headerName: "Schedule Name",
                width: 400,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params) => {
                  const urlEncodedId = Encryption(params?.row?.schedule_id_1);
                  const scheduleName = params.value || "--";
                  return (
                    <Link
                      to={`/schedule/view/${urlEncodedId}`}
                      state={{ from: "schedule_conflict" }}
                    >
                      <div>{scheduleName}</div>
                    </Link>
                  );
                },
              },

              {
                field: "schedule_name_2",
                headerName: "Conflicted Schedule",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const urlEncodedId = Encryption(params?.row?.schedule_id_2);
                  const ConflictedWith = params.value || "--";
                  return (
                    <Link
                      to={`/schedule/view/${urlEncodedId}`}
                      state={{ from: "schedule_conflict" }}
                    >
                      <div>{ConflictedWith}</div>
                    </Link>
                  );
                },
              },
              {
                field: "conflict_start_datetime",
                headerName: "Start Date Time",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const startTime = params?.value || "--"; // Use a hyphen if location is falsy
                  return (
                    <div>
                      {dayjs.utc(startTime).format("MM-DD-YYYY hh:mm a")}
                    </div>
                  );
                },
              },
              {
                field: "conflict_end_datetime",
                headerName: "End Date Time",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const endTime = params?.value || "--"; // Use a hyphen if location is falsy
                  return (
                    <div>{dayjs.utc(endTime).format("MM-DD-YYYY hh:mm a")}</div>
                  );
                },
              },
            ]}
            // initialState={{
            //   pagination: {
            //     rowCount: -1,
            //   },
            // }}
            // onSortModelChange={(e) => {
            //   if (e && e.length > 0) {
            //     setsortOrder(e[0].sort);
            //     setColumnName(e[0].field);
            //   } else {
            //     setsortOrder("");
            //     setColumnName("");
            //   }
            // }}
            // paginationMode="server"
            // sortingMode="server"
            // rowCount={rowCount}
            // pageSizeOptions={[15, 50, 100]}
            // paginationModel={{ pageSize: pageSize, page: page }}
            // onPaginationModelChange={(event) => {
            //   setPage(event?.page);
            //   setPageSize(event?.pageSize);
            // }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
    </>
  );
};

export default ScheduleConflict;
