import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import DefaultImg from "../../../assets/img/DefaultImg.jpg";
import { BsArrowLeft } from "react-icons/bs";
import "./View.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import AddEditPlant from "../AddForm/AddEditPlant";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../FileInput/FileDisplay";

const PlantView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const { company_id, location_id } = useFilter();

  const params = useParams();
  const { id } = params || {};
  const [plant, setPlant] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [DeletePlant, setDeletePlant] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [editData, setEditData] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [clickedplant, setClickedplant] = useState("");
  const [loading, setLoading] = useState(false);
  // //get plant details by id
  const [file, setFiles] = useState([]);
  const [rowsData, setRowsData] = useState();
  const decryptedId = Decryption(params?.id);

  // fetch data by id
  const fetchPlant = async () => {
    setLoading(true);
    try {
      httpService
        .get(`${"plant/get_plant"}/${decryptedId}`)
        .then((response) => {
          // console.log(response.data);
          setRowsData(response.data[0]);
          setPlant(response.data[0]);
          setProfileImg(
            response.data[0].plant_profile_img === null
              ? DefaultImg
              : response.data[0].plant_profile_img
          );
          setFiles(response.data[0].attachment);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPlant();
  }, [params?.id, showLoginPopup]);

  const handleOpen = () => {
    setOpen(true);
    setIsAdd(false);
    setClickedplant("");
    // fetchPlant();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteImg("");
    setDeletePlant(false);
    fetchPlant();
  };

  const HandleDeletePlant = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      // console.log(decryptedId);
      try {
        let response = await httpService.delete(
          `${"plant/delete_plant"}/${decryptedId}`
        );
        if (response) {
          navigate("/plant");
          // console.log(response);
          toast.success("Plant Deleted successfully", {
            position: "top-right",
          });
          setDeletePlant(false);
        }
      } catch (error) {
        setDeletePlant(false);
        console.error("Error deleting plant:", error);
        toast.error(error, {
          position: "top-right",
        });
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const updateImg = plant.attachment;
    const newArray = [...updateImg];
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in plant) {
      if (key !== "activities" && key !== "milestones") {
        if (key === "attachment") {
          for (let i = 0; i < newArray.length; i++) {
            formData.append(`existFile[${i}]`, newArray[i]);
          }
        } else {
          if (plant[key] !== null) {
            const value = key === "sort_order" ? plant[key] || "" : plant[key];
            formData.append(key, value);
          }
        }
      } else {
        plant[key].map((item, index) => {
          if (key === "activities") {
            formData.append(`activity_id[${index}]`, item.id);
          }
          if (key === "milestones") {
            formData.append(`milestone_id[${index}]`, item.id);
          }
        });
      }
    }
    try {
      let response = await httpService.put(
        `plant/edit_plant/${decryptedId}`,
        formData
      );
      if (response) {
        fetchPlant();
        setDeletePlant(false);
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding plant:", error);
      setDeletePlant(false);
      toast.error(error, {
        position: "top-right",
      });
    }
  };

  const [img, setProfileImg] = useState(null);

  const duration = displayDuration(plant?.days, plant?.hours, plant?.minutes);

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="plant-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/plant")} />
              </span>
              Plant Details - [{plant?.name ? plant?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              onClick={() => {
                handleOpen();
                setClickedplant(id);
                setEditData(params.row);
              }}
              type="submit"
              className="btn btn-edit"
            >
              <CiEdit /> Edit{" "}
            </button>

            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                setDeletePlant(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2 mb-4 mb-md-0">
              <div>
                <img src={img !== null ? img : DefaultImg} alt="Plant image" />
              </div>
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Plant Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{plant?.name ? plant?.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Lifetime </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  {/* <p>{plant?.duration ? plant?.duration : "--"}</p> */}
                  <p>{duration ? duration : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s) </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {plant?.version ? plant?.version : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {plant?.description ? plant?.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Company</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{plant?.company_name ? plant?.company_name : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Location</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{plant?.location_name ? plant?.location_name : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {plant?.milestones?.map((milestone, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>
                        {milestone.name ? milestone.name : "--"}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Activity</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {plant?.activities?.map((activity, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>{activity.name}</span>
                    ))}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Task</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {plant?.tasks?.map((task, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>{task.name}</span>
                    ))}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={plant?.attachment}
                  handleDelete={() => setDeletePlant(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <AddEditPlant
          open={open}
          isAdd={isAdd}
          // clickedplant={clickedplant}
          handleClose={() => handleClose()}
          rows={rowsData}
          fetchPlant={() => fetchPlant()}
          companyId={company_id}
          locationId={location_id}
        />
      )}

      <DeleteModal
        open={DeletePlant}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeletePlant()}
      />
    </>
  );
};

export default PlantView;
