export const manage_duration = (days, hours, minutes) => {
  minutes = typeof minutes !== "undefined" ? parseInt(minutes) : 0;
  let weeks = Math.floor(days / 7);
  let remainingDays = days % 7;

  let totalHours = parseInt(remainingDays * 24) + parseInt(hours);
  let totalMinutes = parseInt(totalHours * 60) + parseInt(minutes);

  let newDays = parseInt(Math.floor(totalMinutes / (24 * 60)));
  let newHours = parseInt(Math.floor((totalMinutes % (24 * 60)) / 60));
  let newMinutes = parseInt(totalMinutes % 60);

  return {
    weeks: weeks,
    days: newDays,
    hours: newHours,
    minutes: newMinutes,
  };
};

export const displayDuration = (durationDay, durationHour, durationMin) => {
  const { weeks, days, hours, minutes } = manage_duration(
    durationDay || 0,
    durationHour || 0,
    durationMin || 0
  );

  const timeUnits = [];
  if (weeks > 0) timeUnits.push(`${weeks}${weeks === 1 ? " Week" : "  Weeks"}`);
  if (days > 0) timeUnits.push(`${days}${days === 1 ? " Day" : " Days"}`);
  if (hours > 0) timeUnits.push(`${hours}${hours === 1 ? " Hour" : " Hours"}`);
  if (minutes > 0)
    timeUnits.push(`${minutes}${minutes === 1 ? " Minute" : " Minutes"}`);
  const Newduration = timeUnits.join(" ");
  return Newduration;
};

export const splitDuration = (days, hours, minutes) => {
  let newDays = 0;
  let newHours = 0;
  let newMinutes = 0;

  // Convert the string values to integers
  const parsedDays = parseInt(days || 0);
  const parsedHours = parseInt(hours || 0);
  const parsedMinutes = parseInt(minutes || 0);

  // Assign values to corresponding fields
  if (!isNaN(parsedDays)) {
    newDays = parsedDays;
  }
  if (!isNaN(parsedHours)) {
    newHours = parsedHours;
  }
  if (!isNaN(parsedMinutes)) {
    newMinutes = parsedMinutes;
  }

  // Convert days, hours, and minutes into total minutes
  let totalMinutes = newDays * 24 * 60 + newHours * 60 + newMinutes;

  // Calculate new days, hours, and remaining minutes
  newDays = Math.floor(totalMinutes / (24 * 60));
  let remainingMinutes = totalMinutes % (24 * 60);
  newHours = Math.floor(remainingMinutes / 60);
  newMinutes = remainingMinutes % 60;

  // console.log(newDays, newHours, newMinutes);
  return {
    days: newDays,
    hours: newHours,
    minutes: newMinutes,
  };
};
