import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import "./View.scss";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import DeleteRoom from "../RoomListAdd/DeleteRoom";
import AddEditRoom from "../RoomListAdd/AddEditRoom";
import Milestone from "../../../assets/img/milestone.png";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";

const RoomView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const [room, setRoom] = useState();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const decryptedId = Decryption(params?.roomid);
  const { company_id, location_id } = useFilter();

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
  };
  useEffect(() => {
    setLoading(true);
    if (params?.roomid !== "") {
      httpService
        .get(`rooms/${decryptedId}`)
        .then((res) => {
          setRoom(res.data[0]);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [params, openEdit, showLoginPopup]);

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="room-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/room")} />
              </span>
              Room Details - [{room?.name}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              type="submit"
              className="btn btn-edit"
              onClick={() => setOpenEdit(true)}
            >
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={handleOpen}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2 mb-4 mb-md-0">
              <div
                style={{
                  padding: "5px",
                  margin: "5px",
                  border: "1px solid black",
                }}
              >
                <img
                  alt="room img"
                  src={room?.attached ? room?.attached : Milestone}
                />
              </div>
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{room?.name}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Capacity </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{room?.capacity}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s) </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{room?.version ? room?.version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Status </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{room?.status}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {room?.description ? room?.description : "--"}
                  </p>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {room?.milestones?.map((milestone, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>{milestone.name}</span>
                    ))}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Activity</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {room?.activities?.map((activity, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>{activity.name}</span>
                    ))}
                  </div>
                </div>
                <div className="col-md-2 mt-3">
                  <div className="d-flex justify-content-md-between">
                    <label>Task</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mt-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {room?.tasks?.map((task, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>{task.name}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openEdit ? (
        <AddEditRoom
          open={openEdit}
          isAdd={false}
          clickedRoom={decryptedId}
          handleClose={handleClose}
          companyId={company_id}
          locationId={location_id}
        />
      ) : null}
      {open ? (
        <DeleteRoom
          open={open}
          clickedRoom={decryptedId}
          handleClose={handleClose}
          status={room?.status}
        />
      ) : null}
    </>
  );
};

export default RoomView;
