import React from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "@mui/material/Button";
import { PiUploadSimpleThin } from "react-icons/pi";

function FileInput({
  file,
  attachment,
  handleBlur,
  name,
  setFieldValue,
  name2,
}) {
  return (
    <>
      <div className="col-md-12">
        <div className="form_field_wrapper">
          {/* <label>Attach Files</label> */}
          {(file?.length === 0 || file?.length === undefined) &&
          attachment?.length === 0 ? (
            <label>Attach Files</label>
          ) : (
            <label>Attached</label>
          )}

          {(file?.length === 0 || file?.length === undefined) &&
          attachment?.length === 0 ? (
            <div className="upload-btn-wrap">
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<PiUploadSimpleThin />}
              >
                <input
                  className="file-type"
                  type="file"
                  multiple
                  name={name}
                  onChange={(e) => {
                    // console.log(e.target.files);
                    setFieldValue(name, [...e.target.files]);
                  }}
                  onBlur={handleBlur}
                />
                {attachment?.length > 0 ? (
                  <div>
                    {attachment?.map(
                      (file, index) => (
                        <span key={index}>
                          {file.name}
                          {index !== file?.length - 1 && ", "}
                        </span>
                      ) // Displaying the name of each file
                    )}
                  </div>
                ) : (
                  <span>Upload Files</span>
                )}
              </Button>
            </div>
          ) : (
            <>
              <div className="row justify-content-end">
                <div className="col-md-6 d-flex justify-content-end">
                  <div className="upload-btn-wrap">
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      className="upload-sml-buttton btn btn-border"
                    >
                      Upload
                      <input
                        className="file-type"
                        type="file"
                        multiple
                        name={name}
                        onChange={(e) => {
                          const newFiles = Array.from(e.target.files);
                          // Concatenate new files with existing ones
                          const updatedFiles = file
                            ? [...file, ...newFiles]
                            : newFiles;
                          setFieldValue(name, updatedFiles);
                        }}
                        onBlur={handleBlur}
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="grid gap-3 align-items-center mb-3">
                {file?.length > 0 &&
                  file?.map((data, index) => {
                    return (
                      <>
                        <div
                          className="g-col-4 d-flex flex-row m-2"
                          key={index}
                        >
                          <span>
                            {/* Display the file name */}
                            {data.name.substring(data.name.indexOf("/") + 1)}
                          </span>
                          <p className="card-title ms-2">
                            <RxCross1
                              onClick={() => {
                                // Remove the file from attachment array
                                const updatedAttachment = file.filter(
                                  (_, i) => i !== index
                                );
                                setFieldValue(name, updatedAttachment);
                              }}
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                            />
                          </p>
                        </div>
                      </>
                    );
                  })}
                {attachment && attachment?.length > 0 && (
                  <>
                    {attachment.map((file, index) => (
                      <div key={index} className="g-col-4 d-flex flex-row m-2">
                        {file?.length > 0 &&
                          file?.replace(
                            "https://frosted-farms.s3.ap-south-1.amazonaws.com/",
                            ""
                          )}
                        <p className="card-title ms-2">
                          <RxCross1
                            onClick={() => {
                              // Remove the file from attachment array
                              const updatedAttachment = attachment.filter(
                                (_, i) => i !== index
                              );
                              setFieldValue(name2, updatedAttachment);
                            }}
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          />
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FileInput;
