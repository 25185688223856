import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxCross1, RxPlus } from "react-icons/rx";
import "./Employee.scss";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddEditEmployee from "../../components/Employee/AddForm/AddEditEmployee";
import DeleteModal from "../../components/Model/DeleteModal";
import dayjs from "dayjs";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  TextField,
  gridClasses,
  styled,
} from "@mui/material";
import { HttpService } from "../../service/HttpService";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import DefaultImg from "../../assets/img/DefaultImg.jpg";
import { useAuth } from "../../context/AuthProvider";
import { Encryption } from "../../Mixin/EncryptDecryptId";
import { useFilter } from "../../context/FilterProvider";
import { useFormik } from "formik";
import * as Yup from "yup";

const Employee = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [editData, SetEditData] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [deleteEmp, setDeleteEmp] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);

  const { company_id, location_id } = useFilter();

  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [openPasswordModel, setOpenPasswordModel] = useState(false);

  const handleMenuClick = (event) => {
    // console.log("setDataId ", event);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //fetching the values of employee
  const fetchEmployee = async () => {
    setLoading(true);
    await httpService
      .get(
        `employee/get_employees?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
      )
      .then((response) => {
        setRows(response?.data?.data);
        // setSearchData(response?.data);
        setRowCount(response?.data?.pagination?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchEmployee();
  }, [
    company_id,
    location_id,
    showLoginPopup,
    page,
    pageSize,
    searchData,
    columnName,
    sortOrder,
  ]);

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  ///searching data
  // const searchEmployee = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     rows?.length > 0 &&
  //     rows?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const HandleDeleteEMP = async () => {
    if (deleteEmp) {
      try {
        const response = await httpService.delete(
          `${"employee/delete_employee"}/${deleteEmp}`
        );
        if (response) {
          fetchEmployee();
          navigate("/employee");
          toast.success(response?.message, {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenModel(false);
      } catch (error) {
        setOpenModel(false);
        console.error("Error deleting employee:", error?.response?.data?.error);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      notifyUser: false,
      email: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      notifyUser: Yup.boolean(),
      email: Yup.string(),
    }),
    onSubmit: (values) => {
      setLoading(true);
      httpService
        .post("user/change_password", values)
        .then((response) => {
          response;
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
          error;
        });
      setOpenPasswordModel(false);
    },
  });

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="employee">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Employees</h1>
          </div>
          <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => setOpen(true)}
            >
              <RxPlus /> Add Employee{" "}
            </button>
          </div>
        </div>
        <div className="table-filter-wrap">
          <div className="header_search-wrap">
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button className="btn search-btn search_btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchData(value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div>
            <button type="submit" className="btn filter-btn">
              <img src={Filter} /> Filters{" "}
            </button>
          </div> */}
        </div>
        <div
          style={{
            height: `${rows?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            className="employee-table"
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rows?.length > 0 &&
              rows?.map((row, index) => ({
                ...row,
                id: row.id,
                sequenceNumber: index + 1,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "profile_photo",
                headerName: "Profile Image",
                width: 135,
                align: "center",
                headerAlign: "center",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { profile_photo } = params.row;

                  return (
                    <>
                      <div className="profile-img-wrap">
                        <img
                          src={profile_photo ? profile_photo : DefaultImg}
                          alt="profile"
                          className="profile-img"
                        />
                      </div>
                    </>
                  );
                },
              },
              {
                field: "employee_code",
                headerName: "ID(#)",
                width: 100,
                minWidth: 100,
                renderCell: (params) => {
                  return <span>{params.value || "--"}</span>;
                },
              },
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      {/* <div className="img-wrap">
                        <PiUserCircleThin />
                      </div> */}

                      <span>{params.value || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "role_name",
                headerName: "Role",
                flex: 1,
                minWidth: 200,
              },

              {
                field: "email",
                headerName: "Email",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
              },
              // {
              //   field: "milestone_name",
              //   headerName: "Milestone",
              //   width: 200,
              //   minWidth: 200,
              //   renderCell: (params) => {
              //     const milestones = params?.row?.milestones || []; // Set activities to an empty array if undefined
              //     const milestoneNames = milestones
              //       .map((milestone) => milestone.name) // Use 'name' instead of 'activity_name'
              //       .join(", ");
              //     return <span>{milestoneNames || "--"}</span>;
              //   },
              // },
              // {
              //   field: "activities",
              //   headerName: "Activities",
              //   width: 200,
              //   renderCell: (params) => {
              //     const activities = params?.row?.activities || []; // Set activities to an empty array if undefined
              //     const activityNames = activities
              //       .map((activity) => activity.name) // Use 'name' instead of 'activity_name'
              //       .join(", ");
              //     return <span>{activityNames || "--"}</span>;
              //   },
              // },
              {
                field: "time",
                headerName: "Time",
                flex: 1,
                minWidth: 200,
                valueGetter: (params) => {
                  // console.log(params.row.milestone_id);
                  return ` ${dayjs(params.row.start_time, "HH:mm:ss").format(
                    "h:mm A"
                  )} To ${dayjs(params.row.end_time, "HH:mm:ss").format(
                    "h:mm A"
                  )}`;
                },
              },

              {
                field: "company_name",
                headerName: "Company",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      <span>{params.value || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      <span>{params.value || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "action",
                headerName: "Actions",
                width: 100,
                align: "center",
                headerAlign: "center",
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e),
                            setDataId(params.row.id),
                            SetEditData(params.row);
                          formik.setFieldValue("email", params?.row?.email);
                          setDisableDelete(
                            params?.row?.role_id === 1 ? true : false
                          );
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => viewPage(dataId)}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpen(true);
                            setId(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>{" "}
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenPasswordModel(true);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">
                            Change Password
                          </div>{" "}
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenModel(true);
                            setDeleteEmp(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                          disabled={disableDelete}
                        >
                          <div className="menu-list-items-text">Delete</div>
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
      {open && (
        <AddEditEmployee
          open={open}
          setOpen={(e) => {
            setOpen(e);
            SetEditData("");
            setId("");
          }}
          id={id}
          editData={editData}
          fetchEmployee={() => fetchEmployee()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      {openModel && (
        <DeleteModal
          open={openModel}
          HandleClose={(e) => {
            setOpenModel(e), SetEditData("");
          }}
          HandleDelete={() => {
            HandleDeleteEMP(), SetEditData("");
          }}
        />
      )}
      {openPasswordModel === true && (
        <Modal className="dashboard" open={openPasswordModel}>
          <div className="modal_block">
            <div className="modal_header">
              <div className="cancel-btn-wrap"></div>
              <div className="modal_title_wrapper">
                <h2 className="modal_title">Change Password</h2>
              </div>
              <div className="cancel-btn-wrap">
                <RxCross1
                  onClick={() => {
                    setOpenPasswordModel(false);
                    formik.handleReset();
                  }}
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal_content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <label>
                        Enter New Password
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                        error={
                          formik.touched.newPassword &&
                          Boolean(formik.errors.newPassword)
                        }
                        helperText={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="notifyUser"
                            name="notifyUser"
                            checked={formik.values.notifyUser}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Notify Employee of Password Change"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal_footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Employee;
