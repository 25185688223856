import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import Milestone from "../../../assets/img/milestone.png";
import "./Inventory.scss";
import { Box, Modal } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import { HttpService } from "../../../service/HttpService";
import { useFilter } from "../../../context/FilterProvider";
import { toast } from "react-toastify";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import AddEditInventory from "../../../components/Inventory/AddEditInventory";
import DeleteModal from "../../../components/Model/DeleteModal";

const InventoryView = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
  };
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [deleteImg, setDeleteImg] = useState("");
  const [Deleteinventory, SetDeleteInventory] = useState(false);
  const decryptedId = Decryption(params?.id);
  const { company_id, location_id } = useFilter();
  const [used_quantity, setUsed_quantity] = useState([]);
  const handleOpen = () => {
    setOpen(true);
    // fetchInventory();
  };
  const handleClose = () => {
    setOpen(false);
    setOpenHistory(false);
    setDeleteImg("");
    SetDeleteInventory(false);
    // fetchInventory();
  };

  const handleOpenHistory = () => {
    setOpenHistory(true);
    // fetchUsedQuantity();
  };

  const fetchUsedQuantity = async () => {
    await httpService
      .get(`quantity/get_quantity/${decryptedId}`)
      .then((response) => {
        setUsed_quantity(response?.data[0]);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchInventory = async () => {
    setLoading(true);
    await httpService
      .get(`inventory/get_inventory/${decryptedId}`)
      .then((response) => {
        setInventory(response?.data[0]);
        setDeleteImg("");
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchInventory();
  }, [params?.id, showLoginPopup]);

  const HandleDeleteInventory = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(
          `${"inventory/delete_inventory"}/${decryptedId}`
        );
        navigate("/inventory");
        if (response) {
          toast.success("Inventory Deleted successfully", {
            position: "top-right",
          });
          SetDeleteInventory(false);
          // fetchInventory();
        }
      } catch (error) {
        SetDeleteInventory(false);
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...inventory?.attached]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in inventory) {
      if (key === "attached") {
        for (let i = 0; i < newArray.length; i++) {
          formData.append(`existFile[${i}]`, newArray[i]);
        }
      }
      // else {
      //   if (inventory[key] !== null) {
      //     const value =
      //       key === "sort_order" ? inventory[key] || "" : inventory[key];
      //     formData.append(key, value);
      //   }
      // }
    }
    try {
      let response = await httpService.put(
        `${"inventory/edit_inventory"}/${decryptedId}`,
        formData
      );
      if (response.message) {
        SetDeleteInventory(false);
        // fetchInventory();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      SetDeleteInventory(false);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="inventory-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/inventory")} />
              </span>
              Inventory Details - [{inventory?.name ? inventory?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              type="submit"
              className="btn btn-edit"
              onClick={() => handleOpen()}
            >
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                SetDeleteInventory(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2 mb-4 mb-md-0">
              <div>
                <img
                  src={
                    inventory?.profile_image
                      ? inventory?.profile_image
                      : Milestone
                  }
                  className="img-fluid"
                  alt="Inventory img"
                />
              </div>
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{inventory?.name ? inventory?.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Category </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {inventory?.category_name ? inventory?.category_name : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Location </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  {inventory?.locations && inventory?.locations?.length > 0 ? (
                    inventory?.locations?.map((location, index) => (
                      <span key={index}>
                        {location.location_name ? location.location_name : "--"}
                      </span>
                    ))
                  ) : (
                    <div>--</div>
                  )}
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Room </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  {inventory?.rooms && inventory?.rooms?.length > 0 ? (
                    inventory?.rooms?.map((rooms, index) => (
                      <span key={index}>
                        {rooms.rooms_name ? rooms.rooms_name : "--"}
                      </span>
                    ))
                  ) : (
                    <div>--</div>
                  )}
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Total Qty </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {inventory?.quantity ? inventory?.quantity : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Available Qty </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">--</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Used Qty </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {inventory?.used_quantity ? inventory?.used_quantity : "--"}
                    <span
                      onClick={() => handleOpenHistory()}
                      className="show-history"
                    >
                      Show History
                    </span>
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{inventory?.version ? inventory?.version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {inventory?.description ? inventory?.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    {inventory?.attachment?.length === 0 ? (
                      <div>--</div>
                    ) : (
                      inventory?.attachment?.length > 0 &&
                      inventory?.attachment?.map((image, index) => {
                        const extension = image.split(".").pop().toLowerCase();

                        if (
                          extension === "mp4" ||
                          extension === "webm" ||
                          extension === "mov"
                        ) {
                          return (
                            <div
                              key={index}
                              className="col-sm-4 col-md-3 col-lg-2"
                            >
                              <div className="img-file-wrap">
                                <div className="card img-fluid">
                                  <video
                                    controls
                                    style={{ width: "100%", height: "150px" }}
                                  >
                                    <source
                                      src={image}
                                      type={`video/${extension}`}
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  <div className="card-img-overlay">
                                    <div className="overlay-text-wrap">
                                      <p className="card-title">
                                        {/* {image} */}
                                        <RxCross1
                                          onClick={() => {
                                            SetDeleteInventory(true);
                                            setDeleteImg(index);
                                          }}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (extension === "pdf") {
                          return (
                            <div
                              key={index}
                              className="col-sm-4 col-md-3 col-lg-2"
                            >
                              <div className="img-file-wrap">
                                <div className="card img-fluid">
                                  <a
                                    href={image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={Pdf}></img>
                                  </a>
                                  <div className="card-img-overlay">
                                    <div className="overlay-text-wrap">
                                      <p className="card-title">
                                        {/* {image} */}
                                        <RxCross1
                                          onClick={() => {
                                            SetDeleteInventory(true);
                                            setDeleteImg(index);
                                          }}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (extension === "doc" || extension === "docx") {
                          return (
                            <div
                              key={index}
                              className="col-sm-4 col-md-3 col-lg-2"
                            >
                              <div className="img-file-wrap">
                                <div className="card img-fluid">
                                  <a
                                    href={image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={Word}></img>
                                  </a>
                                  <div className="card-img-overlay">
                                    <div className="overlay-text-wrap">
                                      <p className="card-title">
                                        {/* {image} */}
                                        <RxCross1
                                          onClick={() => {
                                            SetDeleteInventory(true);
                                            setDeleteImg(index);
                                          }}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (
                          extension === "xls" ||
                          extension === "xlsx" ||
                          extension === "csv"
                        ) {
                          return (
                            <div
                              key={index}
                              className="col-sm-4 col-md-3 col-lg-2"
                            >
                              <div className="img-file-wrap">
                                <div className="card img-fluid">
                                  <a
                                    href={image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={Excel}></img>
                                  </a>
                                  <div className="card-img-overlay">
                                    <div className="overlay-text-wrap">
                                      <p className="card-title">
                                        {/* {image} */}
                                        <RxCross1
                                          onClick={() => {
                                            SetDeleteInventory(true);
                                            setDeleteImg(index);
                                          }}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (
                          extension === "jpg" ||
                          extension === "png" ||
                          extension === "jpeg" ||
                          extension === "gif" ||
                          extension === "webp"
                        ) {
                          return (
                            <div
                              key={index}
                              className="col-sm-4 col-md-3 col-lg-2"
                            >
                              <div className="img-file-wrap">
                                <div className="card img-fluid">
                                  <img
                                    style={{ height: "150px" }}
                                    className="card-img-top"
                                    src={image} // Replace `path/to/` with the actual path to your images
                                    alt="Card image"
                                  />
                                  <div className="card-img-overlay">
                                    <div className="overlay-text-wrap">
                                      <p className="card-title">
                                        {/* {image} */}
                                        <RxCross1
                                          onClick={() => {
                                            SetDeleteInventory(true);
                                            setDeleteImg(index);
                                          }}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <AddEditInventory
          open={open}
          handleClose={() => handleClose()}
          inventory_id={decryptedId}
          isAdd={false}
          // fetchInventory={() => fetchInventory()}
          companyId={company_id}
          locationId={location_id}
        />
      )}

      <DeleteModal
        open={Deleteinventory}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeleteInventory()}
      />

      <Modal
        open={openHistory}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="show-more-btn">
          <div className="popup-title">
            <div className="d-flex justify-content-between align-items-center gap-3">
              <h5>Used Quantity History</h5>
              <button onClick={() => handleClose()}>X</button>
            </div>
          </div>

          <div className="popup-header">
            <table>
              <thead className="mb-4">
                <tr>
                  <th>Date</th>
                  <th>Used Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01/01/2024</td>
                  <td>10 Qty</td>
                </tr>

                <tr>
                  <td>02/01/2024</td>
                  <td>10 Qty</td>
                </tr>

                <tr>
                  <td>03/01/2024</td>
                  <td>10 Qty</td>
                </tr>

                <tr>
                  <td>04/01/2024</td>
                  <td>10 Qty</td>
                </tr>

                <tr>
                  <td>05/01/2024</td>
                  <td>10 Qty</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InventoryView;
