import React from "react";
import Modal from "@mui/material/Modal";
import { RxCross1 } from "react-icons/rx";
import { TbAlertHexagonFilled } from "react-icons/tb";
import axios from "axios";
import { GET_ROOMS } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import { useAuth } from "../../../context/AuthProvider";

function DeleteRoom({ open, clickedRoom, handleClose, getRooms, status }) {
  // console.log(status);
  const navigate = useNavigate();
  const { setShowLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const handleSubmit = async () => {
    if (status === "In use") {
      toast.error("Room cannot be deleted as it is In-use", {
        position: "top-right",
      });
      return;
    }
    try {
      await httpService
        .delete(`rooms/${clickedRoom}`)
        .then((res) => {
          console.log("Delete Full Room");

          navigate("/room");

          // toast.success("File Deleted successfully", {
          //   position: "top-right",
          // });
          handleClose();
          if (getRooms) {
            getRooms();
            navigate("/room");
          }
          navigate("/room");
          toast.success("Room Deleted Successfully", {
            position: "top-right",
          });
        })
        .catch((error) => {
          console.error("Error deleting rooms:", error);
        });
    } catch (error) {
      console.error("Error deleting Rooms:", error);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form>
          <div className="modal_block">
            <div className="modal_header">
              <div className="modal_title_wrapper">
                <h2 className="modal_title">Are you Sure ?</h2>
              </div>
              <div className="cancel-btn-wrap">
                <RxCross1 onClick={() => handleClose()} />
              </div>
            </div>
            <div className="modal_content">
              <div className="modal_content_inner">
                <div>
                  <TbAlertHexagonFilled />
                </div>
                <div>
                  <p>Are you sure want to delete this?</p>
                </div>
              </div>
            </div>
            <div className="modal_footer">
              <button onClick={() => handleClose()} className="btn btn-border">
                Cancel
              </button>
              <button
                type="Button"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Yes
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default DeleteRoom;
