// FilePreview.js
import React, { useState } from 'react';
import './FilePreview.scss';
import { Button, Modal } from '@mui/material';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


const FilePreview = ({ images, openModal, setOpenModal }) => {
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const goToPreviousSlide = () => {
    const newIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(newIndex);
  };

  return (
    <div className="carousel">
      <Modal open={openModal} onClose={handleCloseModal} className="modal file_preview">
        <div className="modalContent">
          {/* <Button onClick={goToPreviousSlide} variant="contained" color="primary" className="button"> */}
          <IoIosArrowBack onClick={goToPreviousSlide}/>
          {/* </Button> */}
          <img src={images[currentImageIndex]} alt="modal" className="modalImage" />
          {/* <Button onClick={goToNextSlide} variant="contained" color="primary" className="button"> */}
          <IoIosArrowForward onClick={goToNextSlide}/>
          {/* </Button> */}
        </div>
      </Modal>
    </div>
  );
};

export default FilePreview;
