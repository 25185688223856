export const getFileName = (fName) => {
  const filePath = fName;
  const fileName = filePath?.split("/").pop(); // Extract file name from path
  const fileExtension = fileName?.split(".").pop(); // Extract extension
  const fileNameWithoutExtension = fileName?.split(".")[0]; // Remove extension
  const fileNameWithoutPrefix = fileNameWithoutExtension
    ?.split("-")
    .slice(0, -1)
    .join("-"); // Remove prefix
  const truncatedFileName = fileNameWithoutPrefix?.substring(0, 5); // Get the first 5 characters of the filename

  return truncatedFileName + "." + fileExtension;
};
