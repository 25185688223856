export const FILE_VIEW_URL = process.env.REACT_APP_BASE_UPLOAD_URL;
export const USER_LOGIN = `${process.env.REACT_APP_BASE_URL}/user/login`;
export const GET_ACTIVITY_LIST = `${process.env.REACT_APP_BASE_URL}/activity/get_activity`;
export const ADD_ACTIVITY = `${process.env.REACT_APP_BASE_URL}/activity/add_activity`;
export const DELETE_ACTIVITY = `${process.env.REACT_APP_BASE_URL}/activity/delete_activity`;
export const EDIT_ACTIVITY = `${process.env.REACT_APP_BASE_URL}/activity/edit_activity`;
export const GET_ROOMS = `${process.env.REACT_APP_BASE_URL}rooms/`;
export const UPDATE_ROOM = `${process.env.REACT_APP_BASE_URL}rooms/`;
export const DELETE_ROOM = `${process.env.REACT_APP_BASE_URL}rooms/`;
export const ADD_ROOM = `${process.env.REACT_APP_BASE_URL}rooms/create`;
export const FILE_UPLOAD_ROOM = `${process.env.REACT_APP_BASE_URL}/upload`;
export const GET_MILESTONE_LIST = `${process.env.REACT_APP_BASE_URL}/milestone/get_milestones`;
export const ADD_MILESTONE = `${process.env.REACT_APP_BASE_URL}/activity/add_milestone`;
export const DELETE_MILESTONE = `${process.env.REACT_APP_BASE_URL}/activity/delete_milestone`;
export const EDIT_MILESTONE = `${process.env.REACT_APP_BASE_URL}/activity/edit_milestone`;
export const GET_USER_LIST = `${process.env.REACT_APP_BASE_URL}/user-management/get_users/`;
export const GET_USER_LIST_BY_ID = `${process.env.REACT_APP_BASE_URL}/user-management/get_user/`;
export const ADD_USER = `${process.env.REACT_APP_BASE_URL}user-management/add_user/`;
export const EDIT_USER = `${process.env.REACT_APP_BASE_URL}user-management/edit_user/`;
export const DELETE_USER = `${process.env.REACT_APP_BASE_URL}user-management/delete_user/`;
export const GET_COMPANY_LIST = `${process.env.REACT_APP_BASE_URL}/company/get_companies`;
export const GET_LOCATION_LIST = `${process.env.REACT_APP_BASE_URL}/location/get_location/company`;
export const GET_ACTIVITYBYMILESTONE_LIST = `${process.env.REACT_APP_BASE_URL}/activity/get_activity/milestone`;

// Location
export const GET_LOCATION_LISTPAGE = `${process.env.REACT_APP_BASE_URL}location/get_location/`;
export const ADD_LOCATION = `${process.env.REACT_APP_BASE_URL}location/add_location/`;
export const EDIT_LOCATION = `${process.env.REACT_APP_BASE_URL}location/edit_location/`;
export const DELETE_LOCATION = `${process.env.REACT_APP_BASE_URL}location/delete_location/`;
