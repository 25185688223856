import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FormControl, Select, MenuItem } from "@mui/material";
import { HttpService } from "../../../service/HttpService";
import { useAuth } from "../../../context/AuthProvider";
import SelectInput from "../../SelectInput/SelectInput";

function AddEditLocation({
  open,
  setOpen,
  editData = "",
  id = "",
  fetchLocation,
  isAdd,
  companyId,
}) {
  const { setShowLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const { name, company_id, description, sort_order, version } = editData;
  // const [file, setFile] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    fetchCompany();
  }, [isAdd]);

  const fetchCompany = async () => {
    await httpService
      .get(`company/get_companies`)
      .then((response) => {
        setCompanyData(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("location/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  const autoSequenceOrder = async (payload, setFieldValue) => {
    return await httpService
      .post("location/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          setFieldValue("sort_order", response?.data?.max_sort_order);
          return response?.data?.max_sort_order;
        } else {
          setFieldValue("sort_order", "");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  return (
    <Modal
      className="plant-modal"
      open={open}
      onClose={() => {
        setOpen(false);
        setApiError("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Formik
        initialValues={{
          name: name || "",
          company_id: (companyId > 0 && companyId) || company_id || "",
          description: description || "",
          sort_order: sort_order || "",
          version: version || "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!apiError) {
            try {
              if (isAdd) {
                const payload = {
                  ...values,
                  sort_order: values.sort_order,
                };
                await httpService
                  .put(`location/edit_location/${id}`, payload)
                  .then((res) => {
                    // console.log(res);
                    toast.success("Location Updated Successfully", {
                      position: "top-right",
                    });
                    setOpen(false);
                    fetchLocation();
                  });
              } else {
                await httpService
                  .post("location/add_location/", values)
                  .then((res) => {
                    // console.log(res);
                    toast.success("Location Added Successfully", {
                      position: "top-right",
                    });
                    setOpen(false);
                    fetchLocation();
                  });
              }
            } catch (error) {}
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required("Please Enter Name")
            .max(200, "Name must be less than 200 characters"),
          company_id: Yup.string().required("Please select company"),
          description: Yup.string().max(
            2000,
            "Description must be less than 2000 characters"
          ),
          //   attachment: Yup.array().required("Please Enter Attachment"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          if (values?.sort_order === "" && companyId > 0 && id === "") {
            autoSequenceOrder({ company_id: companyId }, setFieldValue);
          }
          return (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="modal_block">
                <div className="modal_header">
                  <div className="modal_title_wrapper">
                    <h2 className="modal_title">
                      {(isAdd ? "Edit" : "Add") + " Location"}
                    </h2>
                  </div>
                  <div className="cancel-btn-wrap">
                    <RxCross1
                      onClick={() => {
                        setOpen(false);
                        setApiError("");
                        // setFile([]);
                      }}
                    />
                  </div>
                </div>
                <div className="modal_content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="name"
                          placeholder="Enter Your Location Name"
                          variant="outlined"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name && (
                          <span style={{ color: "red" }}>{errors.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <SelectInput
                        label="Company"
                        name="company_id"
                        value={values.company_id}
                        options={companyData}
                        multiple={false}
                        onChange={async (e) => {
                          setFieldValue("company_id", e.target.value);
                          const payload = {
                            company_id: e.target.value,
                            sort_order: values?.sort_order,
                            id: id,
                          };
                          if (id === "") {
                            const sortorders = await autoSequenceOrder(
                              payload,
                              setFieldValue
                            );
                            if (sortorders) {
                              payload["sort_order"] = sortorders;
                            }
                          }
                          if (values?.sort_order > 0 && e.target.value) {
                            matchSequenceOrder(payload);
                          } else {
                            setApiError("");
                          }
                        }}
                        onBlur={(e) => handleBlur(e)}
                        error={touched.company_id && errors.company_id}
                        required={true}
                        displayEmpty
                        disabled={companyId > 0 ? true : false}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>Sequence(#)</label>
                        <TextField
                          name="sort_order"
                          // className={classes.textField}
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="Enter Sequence Number"
                          value={values.sort_order || ""}
                          onChange={async (e) => {
                            handleChange(e);
                            if (values?.company_id && e.target.value) {
                              const payload = {
                                company_id: values?.company_id,
                                sort_order: e.target.value,
                                id: id,
                              };
                              matchSequenceOrder(payload);
                            } else {
                              setApiError("");
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {apiError && (
                          <span style={{ color: "red" }}>{apiError}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>Reff Doc. Version(s)</label>
                        <TextField
                          id="input-version"
                          name="version"
                          type="text"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="Enter Version"
                          value={values.version}
                          onChange={async (e) => {
                            setFieldValue("version", e.target.value);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.version && touched.version && (
                          <span style={{ color: "red" }}>{errors.version}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form_field_wrapper">
                        <label>Description</label>
                        <div>
                          <TextareaAutosize
                            name="description"
                            className="w-100 text-area"
                            aria-label="Type Description"
                            minRows={3}
                            placeholder="Type Description"
                            value={values?.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description && (
                            <span style={{ color: "red" }}>
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal_footer">
                  <button
                    onClick={() => {
                      setOpen(false);
                      setApiError("");
                    }}
                    className="btn btn-border"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary">
                    {isAdd ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddEditLocation;
