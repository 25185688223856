import "./schedule-custom.scss";
import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { RxPlus } from "react-icons/rx";
import { FaDownload, FaFilter } from "react-icons/fa6";
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import AddEditSchedule from "../../../components/Schedule/AddForm/AddEditSchedule";
import DeleteModal from "../../../components/Model/DeleteModal";
import { HttpService } from "../../../service/HttpService";
import { useAuth } from "../../../context/AuthProvider";
import { HiOutlineEye } from "react-icons/hi2";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { useFilter } from "../../../context/FilterProvider";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import DateTimeModal from "./DateTimeModal";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import { handleDownloadExcel } from "../../../Mixin/downloadExcel";
dayjs.extend(utc);

const Schedule = () => {
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [clickedschedule, setClickedschedule] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [schedule_id, setSchedule_id] = useState("");
  const [dataId, setDataId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const { company_id, location_id } = useFilter();
  const [openDateModal, setOpenDateModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deleteSchedule, setDeleteSchedule] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("week");
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);

  // const searchSchedules = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     rows?.length > 0 &&
  //     rows?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const fetchSchedule = () => {
    setLoading(true);
    httpService
      .get(
        `schedule/get_schedule?page=${
          page + 1
        }&pageSize=${pageSize}&start_date=${startDate}&end_date=${endDate}&search=${searchData}`
      )
      .then((response) => {
        setRows(response?.data?.data);
        // setSearchData(response?.data);
        setRowCount(response?.data?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchSchedule();
  }, [
    location_id,
    company_id,
    showLoginPopup,
    page,
    pageSize,
    searchData,
  ]);

  const handleOpen = () => {
    setOpen(true);
    setIsAdd(true);
    setClickedschedule("");
  };

  const handleOpenDateTimeModal = () => {
    setOpenDateModal(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsAdd(false);
    setClickedschedule("");
    setOpenDelete(false);
  };

  const handleDateTimeModal = () => {
    setOpenDateModal(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const HandleDeleteSchedule = async () => {
    if (deleteSchedule) {
      try {
        const response = await httpService.delete(
          `${"schedule/delete_schedule"}/${deleteSchedule}`
        );
        if (response?.success === true) {
          toast.success("Schedule Deleted successfully", {
            position: "top-right",
          });
          fetchSchedule();
        } else {
          throw response;
        }
        setOpenDelete(false);
        fetchSchedule();
      } catch (error) {
        setOpenDelete(false);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const navigate = useNavigate();
  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    navigate(`view/${urlEncodedId}`, { state: { from: "scheduleModule" } });
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="schedule">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Schedules</h1>
          </div>
          <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => handleOpen()}
            >
              <RxPlus /> Add Schedule{" "}
            </button>
            <button
              type="submit"
              className="filter-btn-custm"
              onClick={() => handleOpenDateTimeModal()}
            >
              <FaFilter />
            </button>
          </div>
        </div>
        <div className="table-filter-wrap">
          <div className="header_search-wrap">
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button className="btn search-btn search_btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchData(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${rows?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rows?.length > 0 &&
              rows?.map((row, index) => ({
                ...row,
                id: row.id,
                sequenceNumber: index + 1,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "name",
                headerName: "Schedule Name",
                minWidth: 250,
                flex: 1,
                renderCell: (params) => {
                  return <div>{params.row.name ? params.row.name : "--"}</div>;
                },
              },
              {
                field: "plant_name",
                headerName: "Plant",
                minWidth: 250,
                flex: 1,
              },
              {
                field: "room_name",
                headerName: "Room",
                minWidth: 300,
                flex: 1,
              },
              {
                field: "start_date",
                headerName: "Start Date",
                headerAlign: "center",
                align: "center",
                minWidth: 280,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs(params.row.adjusted_start_date).format(
                        "MMM D, YYYY hh:mm a"
                      )}
                    </div>
                  );
                },
              },
              {
                field: "end_date",
                headerName: "End Date",
                headerAlign: "center",
                align: "center",
                minWidth: 280,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs(params.row.adjusted_end_date).format(
                        "MMM D, YYYY hh:mm a"
                      )}
                    </div>
                  );
                },
              },
              {
                field: "action",
                headerName: "Action",
                headerAlign: "center",
                width: 200,
                align: "center",
                sortable: false,
                disbableColumnMenu: true,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e);
                          setDataId(params.row.id);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            viewPage(dataId);
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpen(true);
                            setIsAdd(false);
                            setSchedule_id(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteSchedule(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Delete</div>
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleDownloadExcel(dataId, setShowLoginPopup),
                              handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Download</div>
                          <FaDownload style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>

      {open === true && (
        <AddEditSchedule
          open={open}
          isAdd={isAdd}
          schedule_id={schedule_id}
          clickedschedule={clickedschedule}
          handleClose={() => {
            handleClose();
            setSchedule_id("");
          }}
          fetchScheduleData={() => fetchSchedule()}
        />
      )}
      <DeleteModal
        open={openDelete}
        HandleClose={() => handleClose()}
        HandleDelete={() => {
          HandleDeleteSchedule(), setClickedschedule("");
        }}
      />

      {openDateModal && (
        <DateTimeModal
          handleDateTimeModal={() => handleDateTimeModal()}
          setStartDate={(e) => setStartDate(e)}
          setEndDate={(e) => setEndDate(e)}
          open={openDateModal}
          fetchSchedule={() => fetchSchedule()}
          selectedRadio={selectedRadio}
          setSelectedRadio={(e) => setSelectedRadio(e)}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};

export default Schedule;
