import "../Style/role-custom.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { HttpService } from "../../../service/HttpService";
import EditRole from "../../../components/Role/EditRole/EditRole";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";

const Role = () => {
  //if not logged in then navigate him to login page
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const userToken = localStorage.getItem("Login Token");
  // console.log(userToken);
  if (userToken === null) {
    navigate("/");
  }

  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [clickedRole, setClickedRole] = useState("");
  const [loading, setLoading] = useState(false);

  const handleMenuClick = (event) => {
    console.log("setDataId ", event);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Fetching the Company
  const fetchRole = () => {
    setLoading(true);
    httpService
      .get("role/get_role")
      .then((response) => {
        // console.log(response.data);
        setRows(response.data); // Assuming the response data is an array of rows
        setSearchData(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchRole();
  }, [showLoginPopup]);

  const [speciality, setSpeciality] = React.useState("");
  const handleChange = (event) => {
    setSpeciality(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setClickedRole("");
    fetchRole();
  };

  const searchCompany = (searchTerm) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const results =
      rows?.length > 0 &&
      rows?.filter((item) => {
        for (let key in item) {
          if (
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(searchTermLowerCase)
          ) {
            return true;
          }
        }
        return false;
      });
    if (results) {
      setSearchData(results);
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  // return (
  //   <div>
  //     <h1 style={{ color: "blue", fontFamily: "wf_title" }}>Coming soon!!</h1>
  //   </div>
  // );

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="role">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Roles</h1>
          </div>
          {/* <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleOpen}
            >
              <RxPlus /> Add Role
            </button>
          </div> */}
        </div>
        <div className="table-filter-wrap">
          <div>
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button className="btn search-btn search_btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => searchCompany(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${searchData?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              searchData?.length > 0 &&
              searchData?.map((row) => ({ ...row, id: row.id }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "name",
                headerName: "Role Name",
                flex: 1,
                minWidth: 200,
              },
              {
                field: "description",
                headerName: "Description",
                flex: 1,
                minWidth: 200,
                sortable: false,
                renderCell: (params) => (
                  <div className="ellipsis">
                    {params.row.description ? params.row.description : "--"}
                  </div>
                ),
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 100,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e), setDataId(params.row.id);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        // key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // setIsAdd(false);
                            setClickedRole(dataId);
                            setOpen(true);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>{" "}
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[15, 50]}
            rowSelection={false}
            autoHeight
            // checkboxSelection
            {...other}
          />
        </div>
      </div>
      <EditRole
        open={open}
        clickedRole={clickedRole}
        fetchRole={() => fetchRole()}
        handleClose={handleClose}
      />
    </>
  );
};

export default Role;
