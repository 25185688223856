import React, { useEffect } from "react";
import Select from "react-select";

const CascadingDropdown = ({
  label,
  employeeOptions,
  roomOptions,
  onSelect,
  setFilteredOptions,
  setSelectedItem,
  setSelectedCategory,
  selectedCategory,
  selectedItem,
  filteredOptions,
}) => {
  useEffect(() => {
    if (selectedCategory === "employee") {
      setFilteredOptions(employeeOptions);
    } else if (selectedCategory === "room") {
      setFilteredOptions(roomOptions);
    }
  }, [selectedCategory, employeeOptions, roomOptions]);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
    setSelectedItem({ value: "all", label: "All" });
    onSelect("all");
  };

  const handleItemChange = (selectedOption) => {
    if (selectedOption.value === "all") {
      setSelectedItem({ value: "all", label: "All" });
      onSelect("");
    } else {
      setSelectedItem(selectedOption);
      onSelect(`${selectedCategory}_id=${selectedOption.value}`);
    }
  };

  const enhancedOptions = [
    { value: "all", label: "All" },
    ...filteredOptions.map((option) => ({
      value: option.id,
      label: selectedCategory === "employee" ? option.user_name : option.name,
    })),
  ];

  return (
    <div className="d-flex flex-nowrap gap-3">
      <Select
        options={[
          { value: "", label: "All" },
          { value: "employee", label: "Employees" },
          { value: "room", label: "Rooms" },
        ]}
        value={{
          value: selectedCategory,
          label: selectedCategory
            ? selectedCategory === "employee"
              ? "Employees"
              : "Rooms"
            : "All",
        }}
        onChange={handleCategoryChange}
        isSearchable={false}
        placeholder="Select Category"
        className="dynamicFilter"
      />

      {selectedCategory && (
        <Select
          options={enhancedOptions}
          value={selectedItem}
          onChange={handleItemChange}
          isMulti={true}
          placeholder={`Search ${
            selectedCategory === "employee" ? "Employee" : "Room"
          }`}
          className="filterDynamic"
        />
      )}
    </div>
  );
};

export default CascadingDropdown;
