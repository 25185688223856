import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const SelectInput = ({
  label,
  name,
  value,
  options,
  multiple = false,
  selectAll = false,
  onChange,
  onBlur,
  error,
  disabled,
  required = false,
}) => {
  return (
    <div className="form_field_wrapper">
      <label>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <FormControl fullWidth>
        <Select
          id={`select-${name}`}
          name={name}
          displayEmpty
          multiple={multiple}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          MenuProps={{
            getcontentanchorel: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            keepMounted: true,
          }}
          renderValue={
            selectAll === true
              ? (selected) => {
                  if (selected?.length === 0) {
                    return <span>Select {label}</span>;
                  }
                  const selectedData =
                    selected &&
                    selected.map(
                      (id) =>
                        options.find((item) => item.id === id)?.prefix_name ||
                        options.find((item) => item.id === id)?.name
                    );

                  return selectedData.join(", ");
                }
              : undefined
          }
        >
          <MenuItem value="" selected disabled>
            Select {label}
          </MenuItem>
          {selectAll === true
            ? [
                options && options?.length > 0 && (
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={value?.length === options?.length}
                        indeterminate={
                          value?.length > 0 && value?.length < options?.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                ),
                options?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox
                      checked={value?.length > 0 && value?.includes(item.id)}
                    />
                    <ListItemText primary={item.prefix_name || item.name} />
                  </MenuItem>
                )),
              ]
            : options?.length > 0 &&
              options?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.name || data.company_name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      {error ? <div style={{ color: "red" }}>{error}</div> : null}
    </div>
  );
};

export default SelectInput;
