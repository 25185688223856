import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Image from "../assets/img/404_animation.gif";

// Styled components for custom styling with animation
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const ErrorTitle = styled.h1`
  font-size: 3rem;
  color: #ff6347;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #0056b3;
  }
`;

const AnimatedImage = styled.img`
  margin-top: 2rem;
  height: 300px;
`;

const PageNotFound = () => {
  return (
    <Wrapper>
      <AnimatedImage src={Image} alt="Not Found" />
      <br />
      <ErrorTitle>Oops! 404 Error </ErrorTitle>
      <ErrorMessage>Page Not Found</ErrorMessage>
      <StyledLink to="/dashboard">Go To Dashboard</StyledLink>
    </Wrapper>
  );
};

export default PageNotFound;
