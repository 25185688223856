import React from "react";
import Excel from "../../assets/img/excel.png";
import Word from "../../assets/img/word.png";
import Pdf from "../../assets/img/pdf.png";
import { RxCross1 } from "react-icons/rx";
import { getFileName } from "../../utils/common";
import "./FileDisplay.scss";
const FileDisplay = ({ attached, handleDelete, setDeleteImg, Tag = "a" }) => {
  return (
    <>
      <div className="col-md-10 images-before-name">
        <div className="row">
          {attached?.length === 0 ? (
            <div>--</div>
          ) : (
            attached?.length > 0 &&
            attached?.map((image, index) => {
              const extension = image?.split(".")?.pop()?.toLowerCase();
              const fileName = getFileName(image);
              if (
                extension === "mp4" ||
                extension === "webm" ||
                extension === "mov"
              ) {
                return (
                  <div
                    key={index}
                    className={
                      handleDelete && setDeleteImg
                        ? "col-sm-4 col-md-3 col-lg-2"
                        : "col-6 col-sm-4 col-md-4 col-lg-4"
                    }
                  >
                    <div className="img-file-wrap">
                      <div className="card img-fluid">
                        <video
                          controls
                          style={{ width: "100%", height: "150px" }}
                        >
                          <source src={image} type={`video/${extension}`} />
                        </video>
                        <div className="card-img-overlay">
                          <div className="overlay-text-wrap">
                            <p className="card-title">
                              {handleDelete && setDeleteImg && (
                                <RxCross1
                                  onClick={() => {
                                    handleDelete();
                                    setDeleteImg(index);
                                  }}
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (extension === "pdf") {
                return (
                  <div
                    key={index}
                    className={
                      handleDelete && setDeleteImg
                        ? "col-sm-4 col-md-3 col-lg-2"
                        : "col-6 col-sm-4 col-md-4 col-lg-4"
                    }
                  >
                    <div className="img-file-wrap">
                      <div className="card img-fluid">
                        <a
                          href={image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Pdf}></img>
                          <span className="imge-name-over">{fileName}</span>
                        </a>
                        <div className="card-img-overlay">
                          <div className="overlay-text-wrap">
                            <p className="card-title">
                              {handleDelete && setDeleteImg && (
                                <RxCross1
                                  onClick={() => {
                                    handleDelete();
                                    setDeleteImg(index);
                                  }}
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (extension === "doc" || extension === "docx") {
                return (
                  <div
                    key={index}
                    className={
                      handleDelete && setDeleteImg
                        ? "col-sm-4 col-md-3 col-lg-2"
                        : "col-6 col-sm-4 col-md-4 col-lg-4"
                    }
                  >
                    <div className="img-file-wrap">
                      <div className="card img-fluid">
                        <a
                          href={image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Word}></img>
                          <span className="imge-name-over">{fileName}</span>
                        </a>
                        <div className="card-img-overlay">
                          <div className="overlay-text-wrap">
                            <p className="card-title">
                              {handleDelete && setDeleteImg && (
                                <RxCross1
                                  onClick={() => {
                                    handleDelete();
                                    setDeleteImg(index);
                                  }}
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (
                extension === "xls" ||
                extension === "xlsx" ||
                extension === "csv"
              ) {
                return (
                  <div
                    key={index}
                    className={
                      handleDelete && setDeleteImg
                        ? "col-sm-4 col-md-3 col-lg-2"
                        : "col-6 col-sm-4 col-md-4 col-lg-4"
                    }
                  >
                    <div className="img-file-wrap">
                      <div className="card img-fluid">
                        <a
                          href={image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Excel}></img>
                          <span className="imge-name-over">{fileName}</span>
                        </a>
                        <div className="card-img-overlay">
                          <div className="overlay-text-wrap">
                            <p className="card-title">
                              {handleDelete && setDeleteImg && (
                                <RxCross1
                                  onClick={() => {
                                    handleDelete();
                                    setDeleteImg(index);
                                  }}
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (
                extension === "jpg" ||
                extension === "png" ||
                extension === "jpeg" ||
                extension === "gif" ||
                extension === "webp"
              ) {
                return (
                  <div
                    key={index}
                    className={
                      handleDelete && setDeleteImg
                        ? "col-sm-4 col-md-3 col-lg-2"
                        : "col-6 col-sm-4 col-md-4 col-lg-4"
                    }
                  >
                    <div className="img-file-wrap">
                      <div className="card img-fluid">
                        <Tag
                          href={image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ height: "150px" }}
                            className="card-img-top"
                            src={image} // Replace `path/to/` with the actual path to your images
                            alt="Card image"
                          />
                          <span className="imge-name-over">{fileName}</span>
                        </Tag>
                        <div className="card-img-overlay">
                          <div className="overlay-text-wrap">
                            <p className="card-title">
                              {handleDelete && setDeleteImg && (
                                <RxCross1
                                  onClick={() => {
                                    handleDelete();
                                    setDeleteImg(index);
                                  }}
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          )}
        </div>
      </div>
    </>
  );
};

export default FileDisplay;
