import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Button from "@mui/material/Button";
import { PiUploadSimpleThin } from "react-icons/pi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { HttpService } from "../../../service/HttpService";
import FileInput from "../../FileInput/FileInput";
import { ListItemIcon } from "@mui/material";
import { useAuth } from "../../../context/AuthProvider";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import SelectInput from "../../SelectInput/SelectInput";

const AddEditEmployee = ({
  open,
  setOpen,
  id = "",
  editData = "",
  fetchEmployee,
  companyId,
  locationId,
}) => {
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [milestoneNames, setMilestoneNames] = useState([]);
  const [activityNames, setActivityNames] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departmentNames, setDepartmentNames] = useState([]);
  const [apiError, setApiError] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllMilestone, setSelectAllMilestone] = useState(false);
  const [loading, setLoading] = useState(false);

  const autoSequenceOrder = async (payload, setFieldValue) => {
    return await httpService
      .post("employee/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          setFieldValue("sort_order", response?.data?.max_sort_order);
        } else {
          setFieldValue("sort_order", "");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };
  const {
    name,
    role_id,
    email,
    phone,
    birthday,
    sunday_working_status,
    monday_working_status,
    tuesday_working_status,
    wednesday_working_status,
    thursday_working_status,
    friday_working_status,
    saturday_working_status,
    milestones,
    activities,
    tasks,
    department_id,
    start_time,
    end_time,
    description,
    profile_photo,
    attachment,
    location_id,
    company_id,
    sort_order,
    version,
    employee_code,
  } = editData;
  useEffect(() => {
    if (company_id) {
      getLocationByCompany(company_id);
    }
    if (location_id) {
      getMilestoneByLocation(location_id);
    }
    if (milestones?.length > 0) {
      getActivityByMilestone(milestones.map((item) => item.id));
    }
    if (activities) {
      getTaskByActivity(activities.map((item) => item.id));
    }
  }, []);

  const fetchCompanies = () => {
    httpService
      .get("company/get_companies")
      .then((response) => {
        setCompanies(response?.data?.data);
        if (id) {
          getLocationByCompany(company_id);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchRoles = () => {
    httpService
      .get("role/get_role")
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDepartments = () => {
    httpService
      .get("department/get_department")
      .then((response) => {
        setDepartmentNames(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchCompanies();
    fetchRoles();
    fetchDepartments();
    if (companyId > 0) {
      getLocationByCompany(companyId);
    }
    if (locationId > 0) {
      getMilestoneByLocation(locationId);
    }
  }, [companyId, locationId, showLoginPopup]);

  const handleDropDownChanage = (key, id) => {
    if (key === "company_id") {
      // Call the API to fetch location data based on the selected company_id
      setActivityNames([]);
      setMilestoneNames([]);
      setTaskData([]);
      getLocationByCompany(id);
    }

    if (key === "location_id") {
      // Call the API to fetch milestone data based on the selected location_id
      setActivityNames([]);
      setTaskData([]);
      getMilestoneByLocation(id);
    }

    if (key === "milestone_id") {
      getActivityByMilestone(id);
    }
    if (key === "activity_id") {
      getTaskByActivity(id);
    }
  };

  const getLocationByCompany = (id) => {
    if (id) {
      return httpService
        .get(`location/get_location/company/${id}`)
        .then((res) => {
          setLocations(res?.data);
          return res;
        })
        .catch((error) => {
          console.error("Error fetching location data:", error);
        });
    }
  };

  const getMilestoneByLocation = (id) => {
    if (id) {
      return httpService
        .get(`milestone/location/get_milestones/${id}`)
        .then((res) => {
          setMilestoneNames(res?.data);
          if (res.length === 0) {
            // Formik.setFieldValue("milestone_id", null);
          }
          return res;
        })
        .catch((error) => {
          console.error("Error fetching milestone data:", error);
        });
    }
  };

  const getActivityByMilestone = (id) => {
    if (id) {
      httpService
        .post(`activity/get_activity/milestone`, { milestone_id: id })
        .then((response) => {
          setActivityNames(response);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const getTaskByActivity = async (id) => {
    try {
      const response = await httpService.post(`task/get_tasks/activity`, {
        activity_id: id,
      });
      setTaskData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSaveCancel = () => {
    setOpen(false);
    setLocations([]);
    setActivityNames([]);
  };

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("employee/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  return (
    <>
      <Modal
        className="employee-modal"
        open={open}
        onClose={() => {
          handleSaveCancel();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          initialValues={{
            name: name || "",
            role_id: role_id || "",
            email: email || "",
            phone: phone || "",
            birthday: dayjs(birthday).format("MM/DD/YYYY") || "",
            profile_photo: "",
            sunday_working_status:
              sunday_working_status >= 0 ? sunday_working_status : 1,
            monday_working_status:
              monday_working_status >= 0 ? monday_working_status : 1,
            tuesday_working_status:
              tuesday_working_status >= 0 ? tuesday_working_status : 1,
            wednesday_working_status:
              wednesday_working_status >= 0 ? wednesday_working_status : 1,
            thursday_working_status:
              thursday_working_status >= 0 ? thursday_working_status : 1,
            friday_working_status:
              friday_working_status >= 0 ? friday_working_status : 1,
            saturday_working_status:
              saturday_working_status >= 0 ? saturday_working_status : 1,
            milestone_id:
              (milestones?.length > 0 && milestones.map((item) => item.id)) ||
              [],
            activity_id:
              (activities?.length > 0 && activities.map((item) => item.id)) ||
              [],
            task_id: (tasks?.length > 0 && tasks.map((item) => item.id)) || [],
            department_id: department_id || [],
            start_time: start_time || "",
            end_time: end_time || "",
            description: description || "",
            file: [],
            attachment: attachment || [],
            location_id: (locationId > 0 && locationId) || location_id || "",
            company_id: (companyId > 0 && companyId) || company_id || "",
            sort_order: sort_order || "",
            version: version || "",
            employee_code: employee_code || "",
          }}
          onSubmit={async (values) => {
            if (!apiError) {
              setLoading(true);
              var form_data = new FormData();
              if (id !== "") {
                for (var key in values) {
                  if (key === "file") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append("attachment", values[key][index]);
                    }
                  } else if (key === "attachment") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `existFile[${index}]`,
                        values[key][index]
                      );
                    }
                  } else if (key === "milestone_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `milestone_id[${index}]`,
                        values[key][index]
                      );
                    }
                  } else if (key === "department_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `department_id[${index}]`,
                        values[key][index]
                      );
                    }
                  } else if (key === "activity_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `activity_id[${index}]`,
                        values[key][index]
                      );
                    }
                  } else if (key === "task_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(`task_id[${index}]`, values[key][index]);
                    }
                  } else {
                    form_data.append(key, values[key]);
                  }
                }
                try {
                  await httpService.put(
                    `${`employee/edit_employee/${id}`}`,
                    form_data
                  );
                  toast.success("Employee Updated Successfully", {
                    position: "top-right",
                  });
                  setLoading(false);
                  setOpen(false);
                  fetchEmployee();
                } catch (error) {
                  setLoading(false);
                  toast.error(error, {
                    position: "top-right",
                  });
                }
              } else {
                for (var key in values) {
                  if (key === "file") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append("attachment", values[key][index]);
                    }
                  } else if (key === "milestone_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `milestone_id[${index}]`,
                        values[key][index]
                      );
                    }
                  } else if (key === "activity_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `activity_id[${index}]`,
                        values[key][index]
                      );
                    }
                  } else if (key === "task_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(`task_id[${index}]`, values[key][index]);
                    }
                  } else if (key === "department_id") {
                    for (let index = 0; index < values[key].length; index++) {
                      form_data.append(
                        `department_id[${index}]`,
                        values[key][index]
                      );
                    }
                  } else {
                    form_data.append(key, values[key]);
                  }
                }
                try {
                  const response = await httpService.post(
                    `${"employee/add_employee"}`,
                    form_data
                  );
                  toast.success("Employee Added Successfully", {
                    position: "top-right",
                  });
                  setLoading(false);
                  setOpen(false);
                  fetchEmployee();
                } catch (error) {
                  setLoading(false);
                  toast.error(error, {
                    position: "top-right",
                  });
                }
              }
            }
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required("Please Enter Email Id"),
            employee_code: Yup.string().required("Please Enter Employee Code"),
            name: Yup.string()
              .required("Please Enter Name")
              .max(200, "Name must be less than 200 characters"),
            role_id: Yup.string().required("Please Select Role"),
            description: Yup.string().max(
              2000,
              "Description must be less than 2000 characters"
            ),
            phone: Yup.string()
              .required("Please Enter Phone Number")
              .min(10)
              .max(10)
              .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                "Phone number is not valid"
              ),
            birthday: Yup.date().required("Please Select Birth Date"),
            profile_photo: Yup.mixed()
              // .required("Profile Image Is Required")
              .test(
                "fileSize",
                "Profile image size must be less than 10MB",
                (value) => {
                  return !value || (value && value.size <= 10485760); // 10MB in bytes
                }
              )
              .test(
                "fileFormat",
                "Profile image must be in jpg, png, jpeg, gif, or webp format",
                (value) => {
                  return (
                    !value ||
                    (value &&
                      [
                        "image/jpeg",
                        "image/png",
                        "image/jpg",
                        "image/gif",
                        "image/webp",
                      ].includes(value.type))
                  );
                }
              ),
            sunday_working_status: Yup.number(),
            monday_working_status: Yup.number(),
            tuesday_working_status: Yup.number(),
            wednesday_working_status: Yup.number(),
            thursday_working_status: Yup.number(),
            friday_working_status: Yup.number(),
            saturday_working_status: Yup.number(),

            milestone_id: Yup.array().test({
              name: "required-if-role",
              test: function (value) {
                const role_id = this.parent.role_id;
                if (parseInt(role_id) === 4 && !value) {
                  return false;
                }
                return true;
              },
              message: "Please select Milestone",
            }),

            activity_id: Yup.array().test({
              name: "required-if-role",
              test: function (value) {
                const role_id = this.parent.role_id;
                if (parseInt(role_id) === 4 && !value) {
                  return false;
                }
                return true;
              },
              message: "Please Select Activity",
            }),

            task_id: Yup.array().test({
              name: "required-if-role",
              test: function (value) {
                const role_id = this.parent.role_id;
                if (parseInt(role_id) === 4 && !value) {
                  return false;
                }
                return true;
              },
              message: "Please Select Task",
            }),
            department_id: Yup.array()
              .min(1, "Please Select at least one department")
              .required("Please Select Department"),
            company_id: Yup.string().required("Please Select Company"),
            location_id: Yup.string().required("Please Select Location"),
            start_time: Yup.string().required("Please Enter Start Time"),
            end_time: Yup.string()
              .required("Please Enter End Time")
              .test(
                "not-same-time",
                "Start time and End time must be different",
                function (value) {
                  const startTime = this.parent.start_time; // Get the value of start_time
                  return startTime !== value; // Return true if start_time and end_time are different
                }
              ),
            // description: Yup.string().required("Please Enter Description"),

            file: Yup.array()
              // .required("Attachment Is Required")
              .test(
                "fileSize",
                "Attachment size must be less than 10MB",
                (value) => {
                  return !value || value.every((file) => file.size <= 10485760); // Check size of each file
                }
              )
              .test("fileFormat", "Invalid file format", (value) => {
                if (!value || value.length === 0) return true; // Skip if no files provided
                const allowedExtensions = {
                  video: ["mp4", "webm", "mov"],
                  pdf: ["pdf"],
                  document: ["doc", "docx"],
                  spreadsheet: ["xls", "xlsx", "csv"],
                  image: ["jpg", "png", "jpeg", "gif", "webp"],
                };
                return value.every((file) => {
                  if (!file || !file.name) return false; // If file or file name is undefined, return false
                  const fileExtension = file.name
                    .split(".")
                    .pop()
                    .toLowerCase();
                  const fileType = Object.keys(allowedExtensions).find((type) =>
                    allowedExtensions[type].includes(fileExtension)
                  );
                  return !!fileType;
                });
              }),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;
            if (
              values?.sort_order === "" &&
              companyId > 0 &&
              locationId > 0 &&
              id === ""
            ) {
              autoSequenceOrder(
                { company_id: companyId, location_id: locationId },
                setFieldValue
              );
            }
            return (
              <>
                {loading && <FullScreenLoader />}
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="modal_block">
                    <div className="modal_header">
                      <div className="modal_title_wrapper">
                        <h2 className="modal_title">
                          {editData && Object.keys(editData).length !== 0
                            ? "Edit Employee"
                            : "Add Employee"}
                        </h2>
                      </div>
                      <div className="cancel-btn-wrap">
                        <RxCross1 onClick={() => handleSaveCancel()} />
                      </div>
                    </div>
                    <div className="modal_content">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="name"
                              // className={classes.textField}
                              placeholder="Enter Your Name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Role<span style={{ color: "red" }}>*</span>
                            </label>
                            <FormControl fullWidth>
                              <Select
                                id="demo-simple-select"
                                displayEmpty
                                name="role_id"
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                                value={values?.role_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <MenuItem value="" selected disabled>
                                  Select your Role
                                </MenuItem>
                                {roles?.length > 0 &&
                                  roles?.map((data) => (
                                    <MenuItem key={data.id} value={data.id}>
                                      {data.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {errors.role_id && touched.role_id && (
                              <span style={{ color: "red" }}>
                                {errors.role_id}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Email<span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              id="input-email"
                              name="email"
                              type="email"
                              InputProps={{}}
                              placeholder="Enter Your Email Address"
                              variant="outlined"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={editData.email ? true : false}
                            />
                            {errors.email && touched.email && (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Phone<span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              id="input-number"
                              type="number"
                              name="phone"
                              InputProps={{ inputProps: { min: 1 } }}
                              placeholder="Enter Your Phone Number"
                              variant="outlined"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone && (
                              <span style={{ color: "red" }}>
                                {errors.phone}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Company<span style={{ color: "red" }}>*</span>
                            </label>
                            <FormControl
                              fullWidth
                              disabled={companyId > 0 ? true : false}
                            >
                              <Select
                                id="demo-simple-select"
                                name="company_id"
                                value={values?.company_id}
                                onChange={(event) => {
                                  setFieldValue(
                                    "company_id",
                                    event.target.value
                                  );
                                  setFieldValue("location_id", "");
                                  setFieldValue("milestone_id", "");
                                  setFieldValue("activity_id", "");
                                  setFieldValue("task_id", "");
                                  handleChange(event);
                                  handleDropDownChanage(
                                    "company_id",
                                    event.target.value
                                  );
                                }}
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                                onBlur={handleBlur}
                                displayEmpty
                              >
                                <MenuItem value="" selected disabled>
                                  Select Company
                                </MenuItem>
                                {companies?.length > 0 &&
                                  companies?.map((data) => (
                                    <MenuItem key={data.id} value={data.id}>
                                      {data.company_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {errors.company_id && touched.company_id && (
                              <span style={{ color: "red" }}>
                                {errors.company_id}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Location<span style={{ color: "red" }}>*</span>
                            </label>
                            <FormControl
                              fullWidth
                              disabled={locationId > 0 ? true : false}
                            >
                              <Select
                                id="demo-simple-select"
                                name="location_id"
                                value={values?.location_id}
                                onChange={(event) => {
                                  setFieldValue(
                                    "location_id",
                                    event.target.value
                                  );
                                  setFieldValue("milestone_id", "");
                                  setFieldValue("activity_id", "");
                                  setFieldValue("task_id", "");
                                  setSelectAllMilestone(false);
                                  setSelectAll(false);
                                  handleChange(event);
                                  handleDropDownChanage(
                                    "location_id",
                                    event.target.value
                                  );
                                  const payload = {
                                    company_id: values?.company_id,
                                    location_id: event.target.value,
                                    sort_order: values?.sort_order,
                                    id: id,
                                  };
                                  if (
                                    values?.company_id &&
                                    values?.sort_order &&
                                    event.target.value
                                  ) {
                                    matchSequenceOrder(payload);
                                  } else {
                                    setApiError("");
                                  }
                                  if (id === "") {
                                    autoSequenceOrder(payload, setFieldValue);
                                  }
                                }}
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                                onBlur={handleBlur}
                                displayEmpty
                              >
                                <MenuItem value="" selected disabled>
                                  Select Location
                                </MenuItem>
                                {locations.length > 0 &&
                                  locations?.map((location) => (
                                    <MenuItem
                                      key={location.id}
                                      value={location.id}
                                    >
                                      {location.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            {errors.location_id && touched.location_id && (
                              <span style={{ color: "red" }}>
                                {errors.location_id}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Milestone
                              {values?.role_id === 4 && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <FormControl fullWidth>
                              <Select
                                id="demo-simple-select"
                                name="milestone_id"
                                multiple
                                value={values?.milestone_id || []}
                                onChange={(event) => {
                                  handleChange(event);
                                  const value = event.target.value;
                                  const milestone = milestoneNames.filter(
                                    (item) => value.includes(item.id)
                                  );
                                  if (values?.activity_id?.length > 0) {
                                    const activities = activityNames.filter(
                                      (item) => {
                                        return values?.activity_id.includes(
                                          item.id
                                        );
                                      }
                                    );
                                    const filteredActivity = activities?.filter(
                                      (activity) =>
                                        milestone
                                          .map((milestone) => milestone.id)
                                          .includes(activity.milestone_id)
                                    );
                                    const selectedActivity =
                                      filteredActivity?.map((item) => item.id);

                                    setFieldValue(
                                      "activity_id",
                                      selectedActivity
                                    );
                                    if (values?.task_id?.length > 0) {
                                      handleDropDownChanage(
                                        "activity_id",
                                        selectedActivity
                                      );
                                      const tasks = taskData.filter((item) => {
                                        return values?.task_id.includes(
                                          item.id
                                        );
                                      });
                                      const filteredTask = tasks.filter(
                                        (task) =>
                                          filteredActivity
                                            .map((activity) => activity.id)
                                            .includes(task.activity_id)
                                      );
                                      const selectedTask = filteredTask?.map(
                                        (item) => item.id
                                      );
                                      setFieldValue("task_id", selectedTask);
                                    }
                                  }

                                  if (value[value.length - 1] === "all") {
                                    const selectedIds =
                                      values?.milestone_id?.length ===
                                      milestoneNames.length
                                        ? []
                                        : milestoneNames.map((item) => item.id);
                                    handleDropDownChanage(
                                      "milestone_id",
                                      selectedIds
                                    );
                                    handleDropDownChanage(
                                      "activity_id",
                                      selectedIds
                                    );
                                    setFieldValue("activity_id", []);
                                    setFieldValue("task_id", []);
                                    setFieldValue("milestone_id", selectedIds);
                                    return;
                                  }
                                  setFieldValue(
                                    "milestone_id",
                                    event.target.value
                                  );
                                  handleDropDownChanage(
                                    "milestone_id",
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                displayEmpty
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <span>Select Milestone</span>;
                                  }
                                  const selectedData =
                                    selected &&
                                    selected.map(
                                      (id) =>
                                        milestoneNames.find(
                                          (item) => item.id === id
                                        )?.name
                                    );
                                  return (
                                    selectedData && selectedData.join(", ")
                                  );
                                }}
                              >
                                <MenuItem value="" selected disabled>
                                  Select Milestone
                                </MenuItem>
                                {milestoneNames?.length > 0 && (
                                  <MenuItem value="all">
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={
                                          values?.milestone_id?.length ===
                                          milestoneNames?.length
                                        }
                                        indeterminate={
                                          values?.milestone_id?.length > 0 &&
                                          values?.milestone_id?.length <
                                            milestoneNames?.length
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                )}
                                {milestoneNames?.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    <Checkbox
                                      checked={
                                        values?.milestone_id?.length > 0 &&
                                        values?.milestone_id?.includes(item.id)
                                      }
                                    />
                                    <ListItemText primary={item.name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.milestone_id && touched.milestone_id && (
                              <span style={{ color: "red" }}>
                                {errors.milestone_id}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Activity
                              {values?.role_id === 4 && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <FormControl fullWidth>
                              <Select
                                id="demo-simple-select"
                                name="activity_id"
                                multiple
                                value={values?.activity_id || []}
                                onChange={(event) => {
                                  // setFieldValue("task_id", "");
                                  handleChange(event);
                                  setFieldValue("task_id", []);
                                  const value = event.target.value;
                                  const activity = activityNames.filter(
                                    (item) => value.includes(item.id)
                                  );
                                  if (values?.task_id?.length > 0) {
                                    const tasks = taskData.filter((item) => {
                                      return values?.task_id.includes(item.id);
                                    });
                                    const filteredTask = tasks.filter((task) =>
                                      activity
                                        .map((activity) => activity.id)
                                        .includes(task.activity_id)
                                    );
                                    const selectedTask = filteredTask?.map(
                                      (item) => item.id
                                    );
                                    setFieldValue("task_id", selectedTask);
                                  }
                                  if (value[value.length - 1] === "all") {
                                    const selectedIds =
                                      values?.activity_id?.length ===
                                      activityNames.length
                                        ? []
                                        : activityNames.map((item) => item.id);
                                    handleDropDownChanage(
                                      "activity_id",
                                      selectedIds
                                    );
                                    setFieldValue("activity_id", selectedIds);
                                    return;
                                  }
                                  setFieldValue(
                                    "activity_id",
                                    event.target.value
                                  );
                                  handleDropDownChanage(
                                    "activity_id",
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                displayEmpty
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <span>Select Activities</span>;
                                  }
                                  const selectedData = selected.map(
                                    (id) =>
                                      activityNames.find(
                                        (item) => item.id === id
                                      )?.prefix_name
                                  );
                                  return (
                                    selectedData && selectedData.join(", ")
                                  );
                                }}
                              >
                                <MenuItem value="" selected disabled>
                                  Select Activity
                                </MenuItem>
                                {activityNames?.length > 0 && (
                                  <MenuItem value="all">
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={
                                          values?.activity_id?.length ===
                                          activityNames?.length
                                        }
                                        indeterminate={
                                          values?.activity_id?.length > 0 &&
                                          values?.activity_id?.length <
                                            activityNames?.length
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                )}
                                {/* List of Activities */}
                                {activityNames?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    <Checkbox
                                      checked={values.activity_id.includes(
                                        item.id
                                      )}
                                    />
                                    <ListItemText primary={item.prefix_name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.activity_id && touched.activity_id && (
                              <span style={{ color: "red" }}>
                                {errors.activity_id}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Tasks
                              {values?.role_id === 4 && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <FormControl fullWidth>
                              <Select
                                id="demo-simple-select"
                                name="task_id"
                                multiple
                                value={values?.task_id || []}
                                onChange={(event) => {
                                  handleChange(event);
                                  const value = event.target.value;
                                  if (value[value.length - 1] === "all") {
                                    const selectedIds =
                                      values?.task_id?.length ===
                                      taskData.length
                                        ? []
                                        : taskData.map((item) => item.id);
                                    setFieldValue("task_id", selectedIds);
                                    return;
                                  }
                                  setFieldValue(
                                    "task_id",
                                    event?.target?.value
                                  );
                                }}
                                onBlur={handleBlur}
                                displayEmpty
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <span>Select Tasks</span>;
                                  }
                                  const selectedData = selected.map(
                                    (id) =>
                                      taskData.find((item) => item.id === id)
                                        ?.prefix_name
                                  );
                                  return selectedData.join(", ");
                                }}
                              >
                                <MenuItem value="" selected disabled>
                                  Select Task
                                </MenuItem>
                                {taskData?.length > 0 && (
                                  <MenuItem value="all">
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={
                                          values?.task_id?.length ===
                                          taskData?.length
                                        }
                                        indeterminate={
                                          values?.task_id?.length > 0 &&
                                          values?.task_id?.length <
                                            taskData?.length
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                )}
                                {taskData?.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    <Checkbox
                                      checked={
                                        values?.task_id?.length > 0 &&
                                        values?.task_id?.includes(item.id)
                                      }
                                    />
                                    <ListItemText primary={item.prefix_name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.task_id && touched.task_id && (
                              <span style={{ color: "red" }}>
                                {errors.task_id}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Departments<span style={{ color: "red" }}>*</span>
                            </label>
                            <FormControl fullWidth>
                              <Select
                                id="demo-simple-select"
                                name="department_id"
                                value={values.department_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                displayEmpty
                                MenuProps={{
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  keepMounted: true,
                                }}
                              >
                                <MenuItem value="" selected disabled>
                                  Select Department
                                </MenuItem>
                                {departmentNames?.map((department) => (
                                  <MenuItem
                                    key={department.id}
                                    value={department.id}
                                  >
                                    {department.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {errors.department_id && touched.department_id && (
                              <span style={{ color: "red" }}>
                                {errors.department_id}
                              </span>
                            )}
                          </div>
                        </div> */}

                        <div className="col-md-6">
                          <SelectInput
                            label="Department"
                            name="department_id"
                            value={values?.department_id}
                            options={departmentNames}
                            multiple={true}
                            onChange={(event) => {
                              const value = event.target.value;
                              if (value[value.length - 1] === "all") {
                                const selectedIds =
                                  values?.department_id?.length ===
                                  departmentNames.length
                                    ? []
                                    : departmentNames.map((item) => item.id);
                                setFieldValue("department_id", selectedIds);
                                return;
                              }
                              setFieldValue(
                                "department_id",
                                event.target.value
                              );
                            }}
                            onBlur={(e) => handleBlur(e)}
                            error={
                              touched?.department_id && errors?.department_id
                            }
                            required={true}
                            selectAll={true}
                            disabled={false}
                          />
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>Sequence(#)</label>
                            <TextField
                              name="sort_order"
                              // className={classes.textField}
                              type="number"
                              InputProps={{ inputProps: { min: 0 } }}
                              placeholder="Enter Sequence Number"
                              value={values.sort_order || ""}
                              onChange={async (e) => {
                                setFieldValue("sort_order", e.target.value);
                                if (
                                  values?.company_id &&
                                  values?.location_id &&
                                  e.target.value
                                ) {
                                  const payload = {
                                    company_id: values?.company_id,
                                    location_id: values?.location_id,
                                    sort_order: e.target.value,
                                    id: id,
                                  };
                                  matchSequenceOrder(payload);
                                } else {
                                  setApiError("");
                                }
                              }}
                              onBlur={handleBlur}
                            />
                            {apiError && (
                              <span style={{ color: "red" }}>{apiError}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Reff Doc. Version(s)
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <TextField
                              name="version"
                              type="text"
                              InputProps={{ inputProps: { min: 0 } }}
                              placeholder="Enter Version"
                              value={values.version}
                              onChange={async (e) => {
                                setFieldValue("version", e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.version && touched.version && (
                              <span style={{ color: "red" }}>
                                {errors.version}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_field_wrapper">
                            <label>
                              Employee Code
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="employee_code"
                              type="text"
                              placeholder="Enter Emp Code"
                              value={values.employee_code}
                              onChange={async (e) => {
                                setFieldValue("employee_code", e.target.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.employee_code && touched.employee_code && (
                              <span style={{ color: "red" }}>
                                {errors.employee_code}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form_field_wrapper">
                            <label>Upload Profile Image</label>
                            <div className="upload-btn-wrap">
                              <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<PiUploadSimpleThin />}
                              >
                                <input
                                  className="file-type"
                                  type="file"
                                  accept="image/*"
                                  // onChange={(e) => {
                                  //   setImage(e.target.files[0]);
                                  //   console.log(e.target.files[0]);
                                  // }}
                                  name="profile_photo"
                                  // value={values.profile_Img}
                                  // onChange={handleChange}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "profile_photo",
                                      ...e.currentTarget.files
                                    )
                                  }
                                  onBlur={handleBlur}
                                />
                                {values.profile_photo ? (
                                  <span>{values.profile_photo?.name}</span> // Displaying the name of the image
                                ) : (
                                  <span>Upload Profile Image</span>
                                )}
                              </Button>
                            </div>
                            {errors.profile_photo && touched.profile_photo && (
                              <span style={{ color: "red" }}>
                                {errors.profile_photo}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="days-arrenge d-flex flex-wrap flex-md-nowrap">
                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="sunday_working_status"
                                      defaultChecked={
                                        values?.sunday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "sunday_working_status",
                                          e.target.checked === true ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.sunday_working_status &&
                                touched.sunday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.sunday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Sun"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="monday_working_status"
                                      defaultChecked={
                                        values?.monday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "monday_working_status",
                                          e.target.checked === true ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.monday_working_status &&
                                touched.monday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.monday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Mon"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="tuesday_working_status"
                                      defaultChecked={
                                        values?.tuesday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "tuesday_working_status",
                                          e.target.checked === true ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.tuesday_working_status &&
                                touched.tuesday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.tuesday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Tue"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="wednesday_working_status"
                                      defaultChecked={
                                        values?.wednesday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "wednesday_working_status",
                                          e.target.checked === true ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.wednesday_working_status &&
                                touched.wednesday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.wednesday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Wed"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="thursday_working_status"
                                      defaultChecked={
                                        values?.thursday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "thursday_working_status",
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.thursday_working_status &&
                                touched.thursday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.thursday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Thu"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="friday_working_status"
                                      defaultChecked={
                                        values?.friday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "friday_working_status",
                                          e.target.checked === true ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.friday_working_status &&
                                touched.friday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.friday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Fri"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="form_field_wrapper">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="saturday_working_status"
                                      defaultChecked={
                                        values?.saturday_working_status === 1
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          "saturday_working_status",
                                          e.target.checked === true ? 1 : 0
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  }
                                />
                              </FormGroup>
                              {errors.saturday_working_status &&
                                touched.saturday_working_status && (
                                  <span style={{ color: "red" }}>
                                    {errors.saturday_working_status}
                                  </span>
                                )}
                            </div>
                            <div className="form_field_wrapper">
                              <TextField
                                defaultValue="Sat"
                                variant="outlined"
                                InputProps={{ readOnly: true }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form_field_wrapper">
                            <label>
                              Date of Birth
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                name="birthday"
                                format="MM/DD/YYYY"
                                value={dayjs(values.birthday)}
                                disableFuture
                                onChange={(date) =>
                                  setFieldValue(
                                    "birthday",
                                    dayjs(date.$d).format("MM/DD/YYYY")
                                  )
                                }
                                onBlur={handleBlur}
                              />
                            </LocalizationProvider>
                            {errors.birthday && touched.birthday && (
                              <span style={{ color: "red" }}>
                                {errors.birthday}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form_field_wrapper">
                            <label>
                              Start Time<span style={{ color: "red" }}>*</span>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              {/* <DemoContainer components={["TimePicker"]}> */}
                              <TimePicker
                                // label="Start Time"
                                // value={startTime}
                                // onChange={(newValue) => setStartTime(newValue)}
                                name="start_time"
                                value={dayjs(values.start_time, "HH:mm:ss")}
                                // onChange={(time) => console.log("time : ", time)}
                                onBlur={handleBlur}
                                onChange={(date) =>
                                  setFieldValue(
                                    "start_time",
                                    dayjs(date.$d).format("HH:mm:ss")
                                  )
                                }
                              />
                              {/* </DemoContainer> */}
                            </LocalizationProvider>
                            {errors.start_time && touched.start_time && (
                              <span style={{ color: "red" }}>
                                {errors.start_time}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_field_wrapper">
                            <label>
                              End Time<span style={{ color: "red" }}>*</span>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              {/* <DemoContainer components={["TimePicker"]}> */}
                              <TimePicker
                                // label="End Time"
                                // value={endTime}
                                // onChange={(newValue) => setEndTime(newValue)}
                                name="end_time"
                                value={dayjs(values.end_time, "HH:mm:ss")}
                                onChange={(date) =>
                                  setFieldValue(
                                    "end_time",
                                    dayjs(date.$d).format("HH:mm:ss")
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              {/* </DemoContainer> */}
                            </LocalizationProvider>
                            {errors.end_time && touched.end_time && (
                              <span style={{ color: "red" }}>
                                {errors.end_time}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form_field_wrapper">
                            <label>Description</label>
                            <div>
                              <TextareaAutosize
                                className="w-100 text-area"
                                aria-label="Type Description"
                                minRows={3}
                                placeholder="Type Description"
                                // value={desc}
                                // onChange={(e) => setDesc(e.target.value)}
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.description && touched.description && (
                                <span style={{ color: "red" }}>
                                  {errors.description}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <FileInput
                          file={values?.file}
                          attachment={values?.attachment}
                          handleBlur={handleBlur}
                          name="file"
                          name2="attachment"
                          setFieldValue={(fieldName, value) =>
                            setFieldValue(fieldName, value)
                          }
                        />
                        {errors.file && touched.file && (
                          <span style={{ color: "red" }}>{errors.file}</span>
                        )}
                      </div>
                    </div>
                    <div className="modal_footer">
                      <button
                        onClick={() => handleSaveCancel()}
                        className="btn btn-border"
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary" type="submit">
                        {editData ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddEditEmployee;
