import React from "react";
import Modal from "@mui/material/Modal";
import { RxCross1 } from "react-icons/rx";
import { TbAlertHexagonFilled } from "react-icons/tb";

function DeleteModal({ open, HandleClose, id = "", HandleDelete, message, user }) {
  return (
    <Modal
      open={open}
      onClose={() => HandleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal_block">
        <div className="modal_header">
          <div className="modal_title_wrapper">
            <h2 className="modal_title">Are you Sure ?</h2>
          </div>
          <div className="cancel-btn-wrap">
            <RxCross1 onClick={() => HandleClose(false)} />
          </div>
        </div>
        <div className="modal_content">
          <div className="modal_content_inner">
            <div>
              <TbAlertHexagonFilled />
            </div>
            <div>
              {user ?
                <p>{message}</p>
                :
                <p>
                  {id !== ""
                    ? "Are you sure you want to delete?"
                    : "Are you sure you want to delete?"}
                </p>
              }
            </div>
          </div>
        </div>
        <div className="modal_footer">
          <button onClick={() => HandleClose(false)} className="btn btn-border">
            Cancel
          </button>
          <button onClick={() => HandleDelete()} className="btn btn-primary">
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
