import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { HttpService } from "../../service/HttpService";
import * as Yup from "yup";
import { TextareaAutosize } from "@mui/material";
import FileInput from "../FileInput/FileInput";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthProvider";

const AddEditCompany = ({
  open,
  setOpen,
  id = "",
  editData = "",
  fetchCompany,
}) => {
  const { setShowLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [apiError, setApiError] = useState("");
  const {
    company_name,
    company_size,
    description,
    sort_order,
    attached,
    version,
  } = editData;

  const autoSequenceOrder = async () => {
    return await httpService
      .get("company/check-auto-sequence")
      .then((response) => {
        if (response?.data?.max_sort_order) {
          formik.setFieldValue("sort_order", response?.data?.max_sort_order);
        } else {
          formik.setFieldValue("sort_order", "");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  useEffect(() => {
    if (id === "") {
      autoSequenceOrder();
    }
  }, []);

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("company/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  const formik = useFormik({
    initialValues: {
      company_name: company_name || "",
      company_size: company_size || "",
      description: description || "",
      sort_order: sort_order || "",
      file: [],
      attached: attached || [],
      version: version || "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string()
        .trim()
        .required("Please Enter Company Name")
        .max(200, "Company Name must be less than 200 characters"),
      company_size: Yup.string()
        .required("Enter Company Size")
        .max(200, "Company Size must be less than 200 "),
      file: Yup.mixed()
        .test("fileSize", "Attachment size must be less than 10MB", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          return files.every((file) => file.size <= 10485760); // Check size of each file
        })
        .test("fileFormat", "Invalid file format", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          if (files.length === 0) return true; // Skip if no files provided
          const allowedExtensions = {
            video: ["mp4", "webm", "mov"],
            pdf: ["pdf"],
            document: ["doc", "docx"],
            spreadsheet: ["xls", "xlsx", "csv"],
            image: ["jpg", "png", "jpeg", "gif", "webp"],
          };
          return files.every((file) => {
            if (!file || !file.name) return false; // If file or file name is undefined, return false
            const fileExtension = file?.name?.split(".").pop().toLowerCase();
            const fileType = Object.keys(allowedExtensions).find((type) =>
              allowedExtensions[type].includes(fileExtension)
            );
            return !!fileType;
          });
        }),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("company_name", values.company_name);
      formData.append("description", values.description || "");
      formData.append("company_size", values.company_size || "");
      formData.append("sort_order", values.sort_order || "");
      formData.append("version", values.version || "");
      // Check if new files are provided
      if (values.file && values.file.length > 0) {
        for (let i = 0; i < values.file.length; i++) {
          formData.append("attached", values.file[i]);
        }
      } else {
        formData.append("attached", []);
      }
      if (values.attached && values.attached.length > 0) {
        for (let i = 0; i < values.attached.length; i++) {
          formData.append(`existFile[${i}]`, values.attached[i]);
        }
      } else {
        formData.append("existFile", []);
      }
      if (!apiError) {
        try {
          if (id === "") {
            const response = await httpService.post(
              `company/add_company`,
              formData
            );
            if (response) {
              toast.success("Company Added Successfully", {
                position: "top-right",
              });
              formik.resetForm();
              setOpen(false);
              fetchCompany();
            }
          } else {
            const response = await httpService.put(
              `${"company/edit_company"}/${id}`,
              formData
            );
            if (response) {
              toast.success("Company Updated Successfully", {
                position: "top-right",
              });
              formik.resetForm();
              setOpen(false);
              fetchCompany();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">
                {editData ? "Edit Company" : "Add Company"}
              </h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1 onClick={() => setOpen(false)} />
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>
                    Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="input-companyname"
                    InputProps={{}}
                    placeholder="Enter Your Company Name"
                    variant="outlined"
                    name="company_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_name}
                  />
                  {formik.touched.company_name &&
                    formik.errors.company_name && (
                      <span style={{ color: "red" }}>
                        {formik.errors.company_name}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>
                    Size<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="number"
                    // InputProps={{ inputProps: { min: 1 } }}
                    id="input-size"
                    placeholder="Enter Your Company Size"
                    variant="outlined"
                    name="company_size"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_size}
                  />
                  {formik.touched.company_size &&
                    formik.errors.company_size && (
                      <span style={{ color: "red" }}>
                        {formik.errors.company_size}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>Sequence(#)</label>
                  <TextField
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    id="input-size"
                    placeholder="Enter Sequence Order"
                    variant="outlined"
                    value={formik.values.sort_order}
                    onChange={(event) => {
                      formik.setFieldValue("sort_order", event.target.value);
                      if (event.target.value) {
                        const payload = {
                          sort_order: event.target.value,
                          id: id,
                        };
                        matchSequenceOrder(payload);
                      } else {
                        setApiError("");
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {apiError && <span style={{ color: "red" }}>{apiError}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>Reff Doc. Version(s)</label>
                  <TextField
                    id="input-version"
                    name="version"
                    type="text"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="Enter Version"
                    value={formik.values.version}
                    onChange={async (e) => {
                      formik.setFieldValue("version", e.target.value);
                    }}
                    onBlur={formik?.handleBlur}
                  />
                  {formik.errors.version && formik.touched.version && (
                    <span style={{ color: "red" }}>
                      {formik.errors.version}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <label>Description</label>
                  <div>
                    <TextareaAutosize
                      className="w-100 text-area"
                      aria-label="Type Description"
                      minRows={3}
                      placeholder="Type Description"
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <span style={{ color: "red" }}>
                          {formik.errors.description}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <div className="upload-btn-wrap">
                    <FileInput
                      file={formik.values?.file}
                      attachment={formik.values?.attached}
                      handleBlur={formik.handleBlur}
                      name="file"
                      name2="attached"
                      setFieldValue={(fieldName, value) =>
                        formik.setFieldValue(fieldName, value)
                      }
                    />
                    {formik.errors.file && formik.touched.file && (
                      <span style={{ color: "red" }}>{formik.errors.file}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <button onClick={() => setOpen(false)} className="btn btn-border">
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {editData ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddEditCompany;
