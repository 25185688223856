import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { HiOutlineEye } from "react-icons/hi2";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxPlus } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import { useAuth } from "../../../context/AuthProvider";
import { HttpService } from "../../../service/HttpService";
import { useFilter } from "../../../context/FilterProvider";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { toast } from "react-toastify";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import DeleteModal from "../../../components/Model/DeleteModal";
import AddEditInventory from "../../../components/Inventory/AddEditInventory";

const Inventory = () => {
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [inventory_id, setInventory_Id] = useState("");
  const [DeleteInventory, setDeleteInventory] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const { company_id, location_id } = useFilter();

  const fetchInventory = () => {
    setLoading(true);
    httpService
      .get("inventory/get_inventory")
      .then((response) => {
        // console.log(response.data);
        setRows(response?.data); // Assuming the response data is an array of rows
        setSearchData(response?.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  // useEffect(() => {
  //   fetchInventory();
  // }, [company_id, location_id, showLoginPopup]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenModel(true);
    setIsAdd(true);
    setInventory_Id("");
  };
  const handleClose = () => {
    setOpenModel(false);
    setIsAdd(false);
    setInventory_Id("");
    setOpenDelete(false);
    // fetchInventory();
  };

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  const searchInventory = (searchTerm) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const results =
      rows?.length > 0 &&
      rows?.filter((item) => {
        for (let key in item) {
          if (
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(searchTermLowerCase)
          ) {
            return true;
          }
        }
        return false;
      });
    if (results) {
      setSearchData(results);
    }
  };

  const HandleDeleteInventory = async () => {
    if (DeleteInventory) {
      try {
        const response = await httpService.delete(
          `${"inventory/delete_inventory"}/${DeleteInventory}`
        );
        if (response) {
          fetchInventory();
          navigate("/inventory");
          toast.success("Inventory Deleted successfully", {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenDelete(false);
        fetchInventory();
      } catch (error) {
        setOpenDelete(false);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return <h1>Coming Soon</h1>;

  // return (
  //   <>
  //     {loading && <FullScreenLoader />}
  //     {/* Render spinner if loading is true */}
  //     <div className="department">
  //       <div className="page-title">
  //         <div className="title-wrap">
  //           <h1>Inventories</h1>
  //         </div>
  //         <div className="button-wrap">
  //           <button
  //             type="submit"
  //             className="btn btn-primary"
  //             onClick={() => handleOpen()}
  //           >
  //             <RxPlus /> Add Inventory
  //           </button>
  //         </div>
  //       </div>
  //       <div className="table-filter-wrap">
  //         <div className="header_search-wrap">
  //           <div className="header_search_bar common_content">
  //             <div className="input-group input_group">
  //               <button className="btn search-btn search_btn">
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   width="15"
  //                   height="15"
  //                   viewBox="0 0 21 21"
  //                   fill="none"
  //                 >
  //                   <circle
  //                     cx="9.98856"
  //                     cy="9.98856"
  //                     r="8.98856"
  //                     stroke="#495057"
  //                     strokeWidth="1.5"
  //                     strokeLinecap="round"
  //                     strokeLinejoin="round"
  //                   />
  //                   <path
  //                     d="M16.2402 16.7071L19.7643 20.222"
  //                     stroke="#495057"
  //                     strokeWidth="1.5"
  //                     strokeLinecap="round"
  //                     strokeLinejoin="round"
  //                   />
  //                 </svg>
  //               </button>
  //               <input
  //                 type="search"
  //                 className="form-control form_control"
  //                 placeholder="Search..."
  //                 aria-label="Search"
  //                 onChange={(e) => searchInventory(e.target.value)}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div>
  //           {/* <button type="submit" className="btn filter-btn">
  //           <img src={Filter} /> Filters{" "}
  //         </button> */}
  //         </div>
  //       </div>

  //       <div style={{ height: 480, width: "100%" }}>
  //         <DataGrid
  //           slots={{
  //             noRowsOverlay: CustomNoRowsOverlay,
  //           }}
  //           rows={
  //             searchData?.length > 0 &&
  //             searchData?.map((row, index) => ({
  //               ...row,
  //               id: row.id,
  //             }))
  //           }
  //           getRowHeight={() => "auto"}
  //           sx={{
  //             [`& .${gridClasses.cell}`]: {
  //               py: 0.5,
  //             },
  //           }}
  //           columns={[
  //             { field: "name", headerName: "Name", width: 330 },
  //             { field: "category", headerName: "Category", width: 430 },
  //             { field: "total", headerName: "Total", width: 220 },
  //             { field: "available", headerName: "Available", width: 220 },
  //             { field: "used", headerName: "Used", width: 220 },
  //             {
  //               field: "action",
  //               headerName: "Actions",
  //               headerAlign: "center",
  //               align: "center",
  //               width: 100,
  //               disableColumnMenu: true,
  //               renderCell: (params) => (
  //                 <>
  //                   <div className="action_icon-weap">
  //                     <IconButton
  //                       onClick={(e) => {
  //                         handleMenuClick(e), setDataId(params.row.id);
  //                       }}
  //                     >
  //                       <GridMoreVertIcon />
  //                     </IconButton>
  //                   </div>
  //                   {anchorEl && dataId === params?.row?.id && (
  //                     <Popover
  //                       className="popup-menu"
  //                       key={dataId}
  //                       open={Boolean(anchorEl)}
  //                       anchorEl={anchorEl}
  //                       onClose={handleCloseMenu}
  //                       anchorOrigin={{
  //                         vertical: "bottom",
  //                         horizontal: "right",
  //                       }}
  //                       transformOrigin={{
  //                         vertical: "top",
  //                         horizontal: "right",
  //                       }}
  //                     >
  //                       <MenuItem
  //                         onClick={() => {
  //                           viewPage(dataId);
  //                         }}
  //                         className="menu-list-items"
  //                       >
  //                         <div className="menu-list-items-text">View</div>
  //                         <HiOutlineEye style={{ cursor: "pointer" }} />
  //                       </MenuItem>
  //                       <MenuItem
  //                         onClick={() => {
  //                           // console.log("params", params.row.id);
  //                           setOpenModel(true);
  //                           setIsAdd(false);
  //                           setInventory_Id(dataId);
  //                           handleCloseMenu();
  //                         }}
  //                         className="menu-list-items"
  //                       >
  //                         <div className="menu-list-items-text">Edit</div>
  //                         <CiEdit style={{ cursor: "pointer" }} />
  //                       </MenuItem>
  //                       <MenuItem
  //                         onClick={() => {
  //                           setOpenDelete(true);
  //                           setDeleteInventory(dataId);
  //                           handleCloseMenu();
  //                         }}
  //                         className="menu-list-items"
  //                       >
  //                         <div className="menu-list-items-text">Delete</div>
  //                         <GoTrash style={{ cursor: "pointer" }} />
  //                       </MenuItem>
  //                     </Popover>
  //                   )}
  //                 </>
  //               ),
  //               sortable: false,
  //             },
  //           ]}
  //           initialState={{
  //             pagination: {
  //               paginationModel: { page: 0, pageSize: 15 },
  //             },
  //           }}
  //           pageSizeOptions={[15, 50]}
  //           checkboxSelection={false} // Set checkboxSelection to false
  //           rowSelection={false}
  //           autoHeight
  //           {...other}
  //         />
  //       </div>
  //     </div>
  //     {openModel === true && (
  //       <AddEditInventory
  //         open={openModel}
  //         isAdd={isAdd}
  //         inventory_id={inventory_id}
  //         handleClose={() => handleClose()}
  //         fetchInventory={() => fetchInventory()}
  //         locationId={location_id}
  //         companyId={company_id}
  //       />
  //     )}
  //     <DeleteModal
  //       open={openDelete}
  //       HandleClose={() => handleClose()}
  //       HandleDelete={() => {
  //         HandleDeleteInventory(), setInventory_Id("");
  //       }}
  //     />
  //   </>
  // );
};

export default Inventory;
