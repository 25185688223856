import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { RxPlus } from "react-icons/rx";
import AddEditTask from "../../components/Task/AddEditForm/AddEditTask";
import "./Task.scss";
import { useNavigate } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { toast } from "react-toastify";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  gridClasses,
  styled,
} from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import DeleteModal from "../../components/Model/DeleteModal";
import { HiOutlineEye } from "react-icons/hi2";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { useAuth } from "../../context/AuthProvider";
import { displayDuration } from "../../Mixin/DisplayDuration";
import { Encryption } from "../../Mixin/EncryptDecryptId";
import { useFilter } from "../../context/FilterProvider";
import SelectInput from "../../components/SelectInput/SelectInput";
import { default as ReactSelect, components } from "react-select";

const Task = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  const [searchData, setSearchData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [task, setTask] = useState([]);
  const [rowsActivityname, setActivityname] = useState([]);
  const [rowsMilstonename, setMilstonename] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [task_id, setTaskId] = useState("");
  const [DeleteTask, setDeletetask] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const { company_id, location_id } = useFilter();

  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [Milstone, setMilstone] = useState([]);
  const [Activity, setActivity] = useState([]);
  const [milestoneState, setMilestoneState] = useState([]);
  const [activityState, setActivityState] = useState([]);

  const fetchTask = () => {
    setLoading(true);
    try {
      httpService
        .get(
          `task/get_tasks?activityFilter=${Activity}&milestoneFilter=${Milstone}&page=${
            page + 1
          }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
        )
        .then((response) => {
          setTask(response?.data?.data);
          // setSearchData(response?.data);
          setRowCount(response?.data?.pagination?.totalRecords);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchMilestone = () => {
    setLoading(true);
    httpService
      .get(`milestone/get_milestones`)
      .then((response) => {
        const OptionsValues = response?.data?.data?.map((items) => {
          return {
            label: items.name,
            value: items.id,
          };
        });
        setMilstonename(OptionsValues); // Assuming the response data is an array of rows
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  // const fetchActivity = () => {
  //   setLoading(true);
  //   httpService
  //     .get(`activity/get_activity`)
  //     .then((response) => {
  //       const OptionsValues = response?.data?.data?.map((items) => {
  //         return {
  //           label: items.name,
  //           value: items.id,
  //         };
  //       });
  //       setActivityname(response?.data?.data); // Assuming the response data is an array of rows
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 300);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.error("Error fetching data:", error);
  //     });
  // };


  const getActivityByMilestone = async (id) => {
    setLoading(true);
    try {
      const res = await httpService.post(`activity/get_activity/milestone`, {
        milestone_id: id,
      });
      setActivityname(res)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTask();
    fetchMilestone();
    // fetchActivity();
  }, [company_id, location_id, showLoginPopup, page, pageSize, searchData, columnName, sortOrder, Activity, Milstone]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenModel(true);
    setIsAdd(true);
    setTaskId("");
  };
  const handleClose = () => {
    setOpenModel(false);
    setIsAdd(false);
    setTaskId("");
    setOpenDelete(false);
    fetchTask();
  };

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`, {
      milestoneState: { from: "taskModule" },
    });
  };

  const handleMilstone = (event) => {
    setMilstone(event?.target?.value);
  };
  const handleActivity = (event) => {
    setActivity(event?.target?.value);
  };
  // const searchtask = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     task?.length > 0 &&
  //     task?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const HandleDeleteTask = async () => {
    if (DeleteTask) {
      try {
        const response = await httpService.delete(`${"task/delete_task"}/${DeleteTask}`);
        if (response) {
          fetchTask();
          navigate("/task");
          toast.success(response?.message, {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenDelete(false);
        fetchTask();
      } catch (error) {
        setOpenDelete(false);
        console.error("Error deleting task:", error?.response?.data?.error);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const handleMilestoneChange = (event) => {
    const selectedValues = event.target.value;
    setActivityState([]);
    setActivity([]);
    if (selectedValues[selectedValues.length - 1] === "all") {
      const selectedIds =
        rowsMilstonename.length === milestoneState.length ? [] : rowsMilstonename.map((item) => item.value);
      setMilestoneState(selectedIds);
      setMilstone(selectedIds);
      getActivityByMilestone(selectedIds);
      return;
    }
    getActivityByMilestone(selectedValues);
    setMilestoneState(selectedValues);
    setMilstone(selectedValues);
  };

  const handleChangeActivity = (selected) => {
    const values = selected?.target?.value;
    if (values[values.length - 1] === "all") {
      const selectedIds = rowsActivityname?.length === Activity.length ? [] : rowsActivityname.map((item) => item.id);
      setActivityState(selectedIds);
      setActivity(selectedIds);
      return;
    }
    setActivityState(values);
    const selectedId = values?.map((item) => item);
    setActivity(selectedId);
  };

  const Option = (props) => {
    const { innerRef, innerProps } = props;
    return (
      <div>
        <components.Option
          {...props}
          innerRef={innerRef}
          innerProps={innerProps}
          sx={{ display: "flex", alignItems: "center" }}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} style={{ marginRight: 10 }} />
          {"  "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="plant">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Tasks</h1>
          </div>

          <div className="right_part">
            <div className="form_field_wrapper">
              {/* <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label">Milestone</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Milstone}
                  label="Milestone"
                  onChange={handleMilstone}
                >
                  <MenuItem value="" disabled selected>
                    Select Milestone
                  </MenuItem>
                  {rowsMilstonename?.length > 0 &&
                    rowsMilstonename?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}

              <label>Milestone</label>
              <FormControl fullWidth>
                <Select
                  id="select-milestones"
                  name="milestones"
                  displayEmpty
                  multiple
                  value={milestoneState} // Directly using milestoneState
                  onChange={handleMilestoneChange}
                  MenuProps={{
                    getcontentanchorel: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    keepMounted: true,
                  }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <span>Select Milestones</span>;
                    }
                    const selectedData =
                      selected &&
                      selected.map(
                        (id) =>
                          rowsMilstonename.find((item) => item.value === id)?.label ||
                          rowsMilstonename.find((item) => item.value === id)?.name
                      );

                    return selectedData?.join(", ");
                  }}>
                  <MenuItem value="" selected disabled>
                    Select Milestones
                  </MenuItem>
                  {rowsMilstonename && rowsMilstonename?.length > 0 && (
                    <MenuItem value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={milestoneState.length === rowsMilstonename?.length}
                          indeterminate={milestoneState.length > 0 && milestoneState.length < rowsMilstonename?.length}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  )}
                  {rowsMilstonename?.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={milestoneState.length > 0 && milestoneState.includes(item.value)} />
                      <ListItemText primary={item.label || item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <SelectInput
                label={"Milestone"}
                name={"milestone_id"}
                required={false}
                value={Milstone}
                multiple={true}
                selectAll={true}
                // onBlur={true}
                options={rowsMilstonename}
                // error={errorMilestone}
                valuesEmployee={[]}
                onChange={(e) => {
                  const value = e.target.value;
                  const milestone = rowsMilstonename?.filter((item) =>
                    value.includes(item.id)
                  );
              
                  if (value[value.length - 1] === "all") {
                    const selectedIds =
                      Milstone?.length === rowsMilstonename.length
                        ? []
                        : rowsMilstonename.map((item) => item.id);
                    // handleChangeLocation(mil_id, selectedIds);
                    // setFieldValue(mil_id, selectedIds);
                    setMilstone(selectedIds)
                    // setFieldValue(act_id, []);
                    return;
                  }
                  setMilstone(e.target.value)
                }}
              /> */}
            </div>
            <div className="form_field_wrapper">
              {/* <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label">Activity</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Activity}
                  label="Activity"
                  onChange={handleActivity}
                >
                  <MenuItem value="" disabled selected>
                    Select Activity
                  </MenuItem>
                  {rowsActivityname?.length > 0 &&
                    rowsActivityname?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}

              <label>Activities</label>
              <FormControl fullWidth>
                <Select
                  id="select-activities"
                  name="activities"
                  displayEmpty
                  multiple
                  value={activityState}
                  onChange={handleChangeActivity}
                  MenuProps={{
                    getcontentanchorel: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    keepMounted: true,
                  }}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <span>Select Activities</span>;
                    }
                    const selectedData =
                      selected &&
                      selected?.map(
                        (id) =>
                          rowsActivityname.find((item) => item.id === id)?.prefix_name ||
                          rowsActivityname.find((item) => item.id === id)?.name
                      );

                    return selectedData?.join(", ");
                  }}>
                  <MenuItem value="" selected disabled>
                    Select Activities
                  </MenuItem>
                  {rowsActivityname && rowsActivityname?.length > 0 && (
                    <MenuItem value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={activityState?.length === rowsActivityname?.length}
                          indeterminate={activityState?.length > 0 && activityState?.length < rowsActivityname?.length}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  )}
                  {rowsActivityname?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Checkbox checked={activityState?.length > 0 && activityState?.includes(item.id)} />
                      <ListItemText primary={item.prefix_name || item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <SelectInput
                label={"Activity"}
                name={"Activity_id"}
                required={false}
                value={Activity}
                multiple={true}
                selectAll={true}
                // onBlur={true}
                options={rowsActivityname}
                // error={errorMilestone}
                valuesEmployee={[]}
                onChange={(e) => {
                  const value = e.target.value;
                  const Activity = rowsActivityname?.filter((item) =>
                    value.includes(item.id)
                  );
              
                  if (value[value.length - 1] === "all") {
                    const selectedIds =
                    Activity?.length === rowsActivityname.length
                        ? []
                        : rowsActivityname.map((item) => item.id);
                    // handleChangeLocation(mil_id, selectedIds);
                    // setFieldValue(mil_id, selectedIds);
                    setActivity(selectedIds)
                    // setFieldValue(act_id, []);
                    return;
                  }
                  setActivity(e.target.value)
                }}
              /> */}
            </div>

            <div className="button-wrap">
              <button type="submit" className="btn btn-primary" onClick={() => handleOpen()}>
                <RxPlus /> Add Task
              </button>
            </div>
          </div>
        </div>
        <div className="table-filter-wrap">
          <div className="header_search-wrap">
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button className="btn search-btn search_btn" style={{ zIndex: 0 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21" fill="none">
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchData(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${task?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}>
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              task?.length > 0 &&
              task?.map((plant) => ({
                ...plant,
                id: plant.id,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "name",
                headerName: "Task Name",
                width: 250,
                renderCell: (params) => {
                  // console.log(params.row);
                  const { name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{name}</span>
                    </div>
                  );
                },
              },
              {
                field: "duration",
                headerName: "Duration",
                width: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const duration = displayDuration(params.row.days, params.row.hours, params.row.minutes);
                  return <div>{duration}</div>;
                },
              },
              {
                field: "activity_name",
                headerName: "Activity",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { activity_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{activity_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "milestone_name",
                headerName: "Milestone",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { milestone_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{milestone_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "company_name",
                headerName: "Company",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const { company_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{company_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                flex: 1,
                minWidth: 200,
                sortable: true,
                renderCell: (params) => {
                  const { location_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{location_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                sortable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e), setDataId(params.row.id);
                        }}>
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}>
                        <MenuItem
                          onClick={() => {
                            viewPage(dataId);
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // console.log("params", params.row.id);
                            setOpenModel(true);
                            setIsAdd(false);
                            setTaskId(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">Edit</div>
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDelete(true);
                            setDeletetask(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items">
                          <div className="menu-list-items-text">Delete</div>
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
      {openModel === true && (
        <AddEditTask
          open={openModel}
          isAdd={isAdd}
          task_id={task_id}
          handleClose={() => handleClose()}
          fetchTask={() => fetchTask()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      <DeleteModal
        open={openDelete}
        HandleClose={() => handleClose()}
        HandleDelete={() => {
          HandleDeleteTask(), setTaskId("");
        }}
      />
    </>
  );
};

export default Task;
