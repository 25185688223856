import React from "react";
import { useAuth } from "../context/AuthProvider";
import { handleDownloadExcel } from "../Mixin/downloadExcel";
import { Button } from "@mui/material";
import { SiMicrosoftexcel } from "react-icons/si";

const DownloadButton = ({ id }) => {
  const { setShowLoginPopup } = useAuth();

  const onDownloadClick = async () => {
    try {
      await handleDownloadExcel(id, setShowLoginPopup);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<SiMicrosoftexcel />}
      className="me-3"
      onClick={() => onDownloadClick()}
    >
      Download
    </Button>
  );
};

export default DownloadButton;
