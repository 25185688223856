import React, { useEffect, useState, version } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { PiUploadSimpleThin } from "react-icons/pi";
import Button from "@mui/material/Button";
import { RxCross1 } from "react-icons/rx";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./Room.scss";
import { toast } from "react-toastify";
import { Checkbox, ListItemText } from "@mui/material";
import { HttpService } from "../../../service/HttpService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import CommonDropdown from "../../../components/SelectInput/CommonDropdown";

const AddEditRoom = ({
  open,
  isAdd,
  handleClose,
  clickedRoom,
  companyId,
  locationId,
}) => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const initialValue = {
    name: "",
    capacity: "",
    status: "Free",
    milestone_id: [],
    activity_id: [],
    company_id: companyId > 0 ? companyId : "",
    location_id: locationId > 0 ? locationId : "",
    sort_order: "",
    description: "",
    attached: [],
  };
  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [mileStoneData, setMileStoneData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [file, setFiles] = useState([]);
  const [apiError, setApiError] = useState("");

  const autoSequenceOrder = async (payload) => {
    return await httpService
      .post("rooms/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ["sort_order"]: response.data.max_sort_order,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ["sort_order"]: "",
          }));
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getCompaniesData = async () => {
    await httpService
      .get("company/get_companies")
      .then((res) => {
        setCompanyData(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data 3:", error);
      });
  };

  const getRoomsData = () => {
    if (clickedRoom !== "") {
      httpService
        .get(`rooms/${clickedRoom}`)
        .then(async (res) => {
          const data = {
            name: res?.data[0]?.name,
            capacity: res?.data[0]?.capacity,
            status: res?.data[0]?.status,
            milestone_id: res?.data[0]?.milestones?.map((item) => item.id),
            activity_id: res?.data[0]?.activities?.map((item) => item.id),
            company_id: res?.data[0]?.company_id,
            location_id: res?.data[0]?.location_id,
            description: res?.data[0]?.description,
            attached: res?.data[0]?.attached,
            sort_order: res?.data[0].sort_order,
            version: res?.data[0].version,
          };
          if (data) {
            if (data.company_id) {
              await getLocationByCompany(data.company_id);
            }
            if (data.location_id) {
              await getMilestoneByLocation(data.location_id);
            }
            if (data.milestone_id?.length > 0) {
              getActivityByMilestone(data.milestone_id);
            }
            setFormData(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data 3:", error);
        });
    }
  };

  useEffect(() => {
    getRoomsData();
    getCompaniesData();
    if (companyId > 0) {
      getLocationByCompany(companyId);
    }
    if (locationId > 0) {
      getMilestoneByLocation(locationId);
    }
    if (formData?.sort_order === "" && companyId > 0 && clickedRoom === "") {
      autoSequenceOrder({ company_id: companyId, location_id: locationId });
    }
  }, [clickedRoom, companyId, locationId, showLoginPopup]);

  const handleChange = (key, event) => {
    setErrors({});
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: event,
    }));

    // console.log(event.target.value, "ccccccc");
    if (key === "company_id") {
      // Call the API to fetch location data based on the selected company_id
      setLocationData([]);
      setMileStoneData([]);
      setActivityData([]);
      getLocationByCompany(event);
      setFormData((prevFormData) => ({
        ...prevFormData,
        location_id: "",
        milestone_id: "",
        activity_id: "",
      }));
    }

    if (key === "location_id") {
      // Call the API to fetch milestone data based on the selected location_id
      setMileStoneData([]);
      setActivityData([]);
      getMilestoneByLocation(event);
      setFormData((prevFormData) => ({
        ...prevFormData,
        milestone_id: "",
        activity_id: "",
      }));
    }

    if (key === "milestone_id") {
      const id = event;
      getActivityByMilestone(id);
      setFormData((prevFormData) => ({
        ...prevFormData,
      }));
    }
  };

  const getLocationByCompany = async (id) => {
    try {
      const res = await httpService.get(`location/get_location/company/${id}`);
      setLocationData(res?.data);
      if (res?.data && locationId) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          location_id: locationId,
        }));
      }
      if (res?.data.length === 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          location_id: "",
        }));
      }
      return res;
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const getMilestoneByLocation = async (id) => {
    try {
      const res = await httpService.get(
        `milestone/location/get_milestones/${id}`
      );
      setMileStoneData(res?.data);
      if (res?.data.length === 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          milestone_id: "",
        }));
      }
      return res;
    } catch (error) {
      console.error("Error fetching milestone data:", error);
    }
  };

  const getActivityByMilestone = async (id) => {
    try {
      const res = await httpService.post(`activity/get_activity/milestone`, {
        milestone_id: id,
      });
      setActivityData(res);
      if (res.length === 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          activity_id: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }
  };

  const handleFileUpload = async (e) => {
    // console.log(e.target.files);
    setFiles(e.target.files);
  };
  const files = file ? [...file] : [];
  // console.log(files, "files");
  const validate = () => {
    setErrors([]);
    let error = {};

    if (!formData.name.trim()) {
      error.name = "Please enter Room Name";
    }
    if (formData.name.length > 200) {
      error.name = "Room Name must be less than 200 characters";
    }
    if (!formData.capacity) {
      error.capacity = "Please select Room Capacity";
    }
    if (
      !formData?.capacity ||
      formData.capacity <= 0 ||
      formData.capacity > 99999
    ) {
      error.capacity = "Please enter a valid room capacity (1-99999)";
    }
    if (!formData.status) {
      error.status = "Please select Room Status";
    }
    if (!formData.company_id || formData.company_id.length === 0) {
      error.company_id = "Please select at least one company";
    }
    if (!formData.location_id || formData.location_id.length === 0) {
      error.location_id = "Please select at least one location";
    }
    if (!formData.milestone_id || formData.milestone_id.length === 0) {
      error.milestone_id = "Please select at least one milestone";
    }
    if (!formData.activity_id || formData.activity_id.length === 0) {
      error.activity_id = "Please select at least one activity";
    }
    if (formData.description.length > 2000) {
      error.description = "Room Description must be less than 2000 characters";
    }
    if (files) {
      if (files[0]?.size > 10485760) {
        error.attached = "File size should be less than 10 MB";
      }
      const allowedExtensions = ["jpg", "png", "jpeg", "gif", "webp"];
      const invalidFiles = Array.from(files).filter(
        (file) =>
          !allowedExtensions.includes(file.name.split(".").pop().toLowerCase())
      );
      if (invalidFiles.length > 0) {
        error.attached = "Invalid file format. Only image files are allowed";
      }
    }
    // if (!formData.description) {
    //   error.description = "Please enter Room Description";
    // }
    if (Object.keys(error).length > 0) {
      setErrors(error);
      return false;
    }
    return true;
  };
  const handleSave = async () => {
    if (validate()) {
      const form_Data = new FormData();
      form_Data.append("name", formData.name);
      form_Data.append("capacity", formData.capacity);
      form_Data.append("status", formData.status);
      form_Data.append("description", formData.description);
      form_Data.append("sort_order", formData.sort_order || "");
      form_Data.append("version", formData.version || "");

      if (formData.milestone_id) {
        formData.milestone_id.forEach((id, index) => {
          form_Data.append(`milestone_id[${index}]`, id);
        });
      }
      if (formData.activity_id) {
        formData.activity_id.forEach((id, index) => {
          form_Data.append(`activity_id[${index}]`, id);
        });
      }
      form_Data.append("company_id", formData.company_id);
      form_Data.append("location_id", formData.location_id);

      form_Data.append("attached", files[0]);

      if (!apiError) {
        if (isAdd) {
          await httpService
            .post("rooms/create", form_Data)
            .then((res) => {
              handleClose();
              toast.success("Room Added successfully", {
                position: "top-right",
              });
            })
            .catch((error) => {
              console.error("Error fetching data Down:", error);
            });
        } else {
          await httpService
            .put(`rooms/${clickedRoom}`, form_Data)
            .then((res) => {
              handleClose();
              toast.success("Room Updated Successfully", {
                position: "top-right",
              });
            })
            .catch((error) => {
              console.error("Error fetching data 11:", error);
            });
        }
      }
    }
  };

  const showError = (errorMessage) => {
    return (
      errorMessage && (
        <span className="validation-error text-danger">{errorMessage}</span>
      )
    );
  };

  const matchSequenceOrder = async (payload) => {
    return await httpService
      .post("rooms/check-sequence", payload)
      .then((response) => {
        setApiError("");
        return response;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiError(error?.message);
        return error;
      });
  };

  return (
    <Modal
      className="room-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form encType="multipart/form-data">
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">
                {isAdd ? "Add Room" : "Edit Room"}
              </h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1 onClick={() => handleClose()} />
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label htmlFor="room-name">
                    Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="room-name"
                    InputProps={{}}
                    placeholder="Enter Your Room Name"
                    variant="outlined"
                    value={formData?.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                  {showError(errors?.name)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form_field_wrapper">
                  <label htmlFor="room-capacity">
                    Capacity<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    type="number"
                    id="room-capacity"
                    InputProps={{ inputProps: { min: 1 } }}
                    placeholder="Select Capacity"
                    variant="outlined"
                    value={formData?.capacity}
                    onChange={(e) => handleChange("capacity", e.target.value)}
                  />
                  {showError(errors?.capacity)}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form_field_wrapper">
                  <label htmlFor="room-status">Status</label>
                  <span style={{ color: "red" }}>*</span>
                  <FormControl fullWidth>
                    <Select
                      id="room-status"
                      value={formData?.status}
                      onChange={(e) => {
                        handleChange("status", e.target.value);
                      }}
                      placeholder="Select Status"
                      // multiple
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select your Status
                      </MenuItem>
                      <MenuItem value="Free" selected>
                        Free
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {showError(errors?.status)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label htmlFor="room-Companies">
                    Company<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl
                    fullWidth
                    disabled={companyId > 0 ? true : false}
                  >
                    <Select
                      id="room-companies"
                      value={parseInt(formData?.company_id) || ""}
                      onChange={(e) => {
                        handleChange("company_id", e.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <span>Select Companies</span>;
                        }
                        const selectedCompany = companyData.find(
                          (item) => item.id === selected
                        );
                        return selectedCompany?.company_name || "";
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled selected>
                        Select Companies
                      </MenuItem>
                      {companyData?.length > 0 &&
                        companyData?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.company_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {showError(errors?.company_id)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label htmlFor="room-activity">
                    Location<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl
                    fullWidth
                    disabled={locationId > 0 ? true : false}
                  >
                    <Select
                      id="room-activity"
                      value={parseInt(formData?.location_id) || ""}
                      onChange={(e) => {
                        handleChange("location_id", e.target.value);
                        const payload = {
                          company_id: formData?.company_id,
                          location_id: e.target.value,
                          sort_order: formData?.sort_order,
                          id: clickedRoom,
                        };
                        if (
                          formData?.company_id &&
                          formData?.sort_order &&
                          e.target.value
                        ) {
                          matchSequenceOrder(payload);
                        } else {
                          setApiError("");
                        }
                        if (clickedRoom === "") {
                          autoSequenceOrder(payload);
                        }
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <span>Select Location</span>;
                        }
                        const selectedLocation = locationData.find(
                          (item) => item.id === selected
                        );
                        return selectedLocation?.name;
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="" disabled selected>
                        Select Location
                      </MenuItem>
                      {locationData.length > 0 &&
                        locationData?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {showError(errors?.location_id)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label htmlFor="room-milestone">
                    Milestones<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl fullWidth>
                    <Select
                      id="room-milestone"
                      multiple
                      value={formData?.milestone_id || []}
                      onChange={(e) => {
                        handleChange("activity_id", []);
                        const value = e.target.value;
                        const milestone = mileStoneData.filter((item) =>
                          value.includes(item.id)
                        );

                        if (formData?.activity_id?.length > 0) {
                          const activities = activityData.filter((item) => {
                            return formData?.activity_id.includes(item.id);
                          });
                          const filteredActivity = activities.filter(
                            (activity) =>
                              milestone
                                .map((milestone) => milestone.id)
                                .includes(activity.milestone_id)
                          );
                          const selectedActivity = filteredActivity?.map(
                            (item) => item.id
                          );
                          handleChange("activity_id", selectedActivity);
                        }

                        if (value[value.length - 1] === "all") {
                          const selectedIds =
                            formData?.milestone_id?.length ===
                            mileStoneData.length
                              ? []
                              : mileStoneData.map((item) => item.id);

                          handleChange("milestone_id", selectedIds);
                          return;
                        }
                        handleChange("milestone_id", e.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Select Milestone</span>;
                        }
                        const selectedData = selected.map(
                          (id) =>
                            mileStoneData.find((item) => item.id === id)?.name
                        );
                        return selectedData.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      {mileStoneData?.length > 0 && (
                        <MenuItem value="all">
                          <Checkbox
                            checked={
                              formData?.milestone_id?.length ===
                              mileStoneData?.length
                            }
                            indeterminate={
                              formData?.milestone_id?.length > 0 &&
                              formData?.milestone_id?.length <
                                mileStoneData?.length
                            }
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      )}
                      {mileStoneData?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          <Checkbox
                            checked={
                              formData?.milestone_id?.length > 0 &&
                              formData?.milestone_id?.includes(item.id)
                            }
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {showError(errors?.milestone_id)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label htmlFor="room-activity">
                    Activities<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl fullWidth>
                    <Select
                      id="room-activity"
                      multiple
                      value={formData?.activity_id || []}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value[value.length - 1] === "all") {
                          const selectedIds =
                            formData?.activity_id?.length ===
                            activityData.length
                              ? []
                              : activityData.map((item) => item.id);

                          handleChange("activity_id", selectedIds);
                          return;
                        }
                        handleChange("activity_id", e.target.value);
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span>Select Activities</span>;
                        }
                        const selectedData = selected.map(
                          (id) =>
                            activityData.find((item) => item.id === id)
                              ?.prefix_name
                        );
                        return selectedData.join(", ");
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        keepMounted: true,
                      }}
                    >
                      {activityData?.length > 0 && (
                        <MenuItem value="all">
                          <Checkbox
                            checked={
                              formData?.activity_id?.length ===
                              activityData?.length
                            }
                            indeterminate={
                              formData?.activity_id?.length > 0 &&
                              formData?.activity_id?.length <
                                activityData?.length
                            }
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      )}
                      {activityData?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={formData?.activity_id?.includes(item.id)}
                          />
                          <ListItemText primary={item.prefix_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {showError(errors?.activity_id)}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>Sequence(#)</label>
                  <TextField
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    id="input-size"
                    name="sort_order"
                    placeholder="Enter Sequence Number"
                    variant="outlined"
                    value={formData?.sort_order}
                    onChange={(e) => {
                      handleChange("sort_order", e.target.value);
                      if (
                        formData?.company_id &&
                        formData?.location_id &&
                        e.target.value > 0
                      ) {
                        const payload = {
                          company_id: formData?.company_id,
                          location_id: formData?.location_id,
                          sort_order: e.target.value,
                          id: clickedRoom,
                        };
                        matchSequenceOrder(payload);
                      } else {
                        setApiError("");
                      }
                    }}
                  />
                  {apiError && <span style={{ color: "red" }}>{apiError}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_field_wrapper">
                  <label>
                    Reff Doc. Version(s)
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="version"
                    type="text"
                    InputProps={{ inputProps: { min: 0 } }}
                    placeholder="Enter Version"
                    value={formData.version}
                    onChange={(e) => {
                      handleChange("version", e.target.value);
                    }}
                  />
                  {showError(errors?.version)}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <label htmlFor="room-description">Description</label>
                  <div>
                    <TextareaAutosize
                      id="room-description"
                      className="w-100 text-area"
                      aria-label="Type Description"
                      minRows={3}
                      placeholder="Type Description"
                      value={formData?.description}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                    />
                    {showError(errors?.description)}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <label htmlFor="room-file">Profile Image </label>
                  <div className="upload-btn-wrap">
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<PiUploadSimpleThin />}
                    >
                      <input
                        className="file-type"
                        type="file"
                        id="room-file"
                        onChange={handleFileUpload}
                        accept="image/*"
                      />
                      {file && file.length > 0 ? (
                        <span>{file[0].name}</span> // Displaying the name of the image
                      ) : (
                        <span>Upload Profile Image</span>
                      )}
                    </Button>
                    {showError(errors?.attached)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <button className="btn btn-border" onClick={() => handleClose()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleSave()}
            >
              {!isAdd ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddEditRoom;
