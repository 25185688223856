import "../Schedule/ScheduleListAdd/schedule-custom.scss";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, gridClasses, Modal, styled } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { Encryption } from "../../Mixin/EncryptDecryptId";
import { useFilter } from "../../context/FilterProvider";
import { FaFilter } from "react-icons/fa";
import DateTimeModal from "../Schedule/ScheduleListAdd/DateTimeModal";
import CascadingDropdown from "../../components/CascadingDropdown";
import Select from "react-select";
import { IoIosArrowBack } from "react-icons/io";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

dayjs.extend(utc);

const ScheduleList = () => {
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [clickedschedule, setClickedschedule] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [schedule_id, setSchedule_id] = useState("");
  const [dataId, setDataId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const { company_id, location_id } = useFilter();
  const [openDateModal, setOpenDateModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateCopy, setStartDateCopy] = useState("");
  const [endDateCopy, setEndDateCopy] = useState("");
  const [deleteSchedule, setDeleteSchedule] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("week");
  const [employees, setEmployees] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedScheduleValue, setSelectedScheduleValue] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "All",
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState({
    value: "all",
    label: "All",
  });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [scheduleConfliced, setScheduleConflicted] = useState(0);
  const [openModel, setOpenModel] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);

  const handleChangeSchedule = (value) => {
    if (value === "Today") {
      setStartDate(dayjs().format("MM-DD-YYYY"));
      setEndDate("");
    } else if (value === "This_Week") {
      setStartDate(dayjs().startOf("week").format("MM-DD-YYYY"));
      setEndDate(dayjs().endOf("week").format("MM-DD-YYYY"));
    } else if (value === "Date_Range") {
      setOpenModel(true);
    } else if (value === "all") {
      setStartDate("");
      setEndDate("");
    }
  };

  const options = [
    { value: "all", label: "All" },
    { value: "Today", label: "Today" },
    { value: "This_Week", label: "This Week" },
    { value: "Date_Range", label: "Date Range" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedScheduleValue(selectedOption.value);
    handleChangeSchedule(selectedOption.value);
  };

  // const searchSchedules = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     rows?.length > 0 &&
  //     rows?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const fetchSchedule = () => {
    setLoading(true);

    const queryParams = [];
    if (selectedOption.value !== "" && startDate)
      queryParams.push(`start_date=${startDate}`);
    if (selectedOption.value !== "" && endDate)
      queryParams.push(`end_date=${endDate}`);
    if (selectedFilter && selectedFilter !== "all") {
      queryParams.push(selectedFilter);
    }

    // Construct the query string
    const queryString = queryParams.length ? `&${queryParams.join("&")}` : "";

    httpService
      .get(
        `schedule/get_schedule?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchData}${queryString}`
      )
      .then((response) => {
        setRows(response?.data?.data);
        setRowCount(response?.data?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchEmpNRooms = () => {
    httpService
      .get("user-management/get_users")
      .then((response) => setEmployees(response?.data));
    httpService.get("rooms").then((response) => setRooms(response?.data?.data));
  };

  useEffect(() => {
    fetchEmpNRooms();
    fetchSchedule();
  }, [location_id, company_id, showLoginPopup, page, pageSize, searchData]);

  useEffect(() => {
    if (
      selectedScheduleValue === "This_Week" ||
      selectedScheduleValue === "Today" ||
      selectedScheduleValue === "all" ||
      selectedFilter ||
      selectedFilter === ""
    ) {
      fetchSchedule();
    }
  }, [startDate, endDate, selectedFilter, selectedScheduleValue]);

  const handleOpen = () => {
    setOpen(true);
    setIsAdd(true);
    setClickedschedule("");
  };

  const handleOpenDateTimeModal = () => {
    setOpenDateModal(true);
  };

  const handleDateTimeModal = () => {
    setOpenDateModal(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const navigate = useNavigate();
  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    navigate(`/reports/${urlEncodedId}`);
  };

  const handleSubmit = () => {
    // fetchSchedule();
    setStartDate(startDateCopy);
    setEndDate(endDateCopy);
    setOpenModel(false);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedOption({
      value: "all",
      label: "All",
    });
    setSelectedFilter("");
    setSelectedScheduleValue("all");
    setSelectedCategory("");
    setSelectedItem("");
    setFilteredOptions([]);
    fetchSchedule();
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="schedule">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Schedules Report</h1>
          </div>
          {/* <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => handleOpen()}
            >
              <RxPlus /> Add Schedule{" "}
            </button>
            <button
              type="submit"
              className="filter-btn-custm"
              onClick={() => handleOpenDateTimeModal()}
            >
              <FaFilter />
            </button>
          </div> */}
          <div className="right_side d-flex align-items-center">
            <div
              className={`form_field_wrapper ${
                selectedCategory !== "" ? "fiter_emp_room" : ""
              }`}
            >
              <label>Filter by Employee or Room</label>
              <CascadingDropdown
                employeeOptions={employees}
                roomOptions={rooms}
                onSelect={handleFilterSelect}
                setFilteredOptions={setFilteredOptions}
                setSelectedItem={setSelectedItem}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                selectedItem={selectedItem}
                filteredOptions={filteredOptions}
              />
            </div>
            <div className="form_field_wrapper fiter_range">
              <label>Filter by Range</label>
              <Select
                options={options}
                value={selectedOption}
                onChange={(e) => handleChange(e)}
                placeholder="Select Range"
                isSearchable={false}
              />
            </div>
            <div className="reset-button view-all-btn">
              <label></label>
              <button
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="table-filter-wrap">
          <div className="header_search-wrap">
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button
                  className="btn search-btn search_btn"
                  style={{ zIndex: 0 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchData(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${rows?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rows?.length > 0 &&
              rows?.map((row, index) => ({
                ...row,
                id: row.id,
                sequenceNumber: index + 1,
              }))
            }
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
            }}
            onRowClick={(params) => viewPage(params?.id)}
            columns={[
              {
                field: "name",
                headerName: "Schedule Name",
                minWidth: 250,
                flex: 1,
                renderCell: (params) => {
                  return <div>{params.row.name ? params.row.name : "--"}</div>;
                },
              },
              {
                field: "plant_name",
                headerName: "Plant",
                minWidth: 200,
                flex: 1,
              },
              {
                field: "room_name",
                headerName: "Room",
                minWidth: 200,
                flex: 1,
              },
              {
                field: "company_name",
                headerName: "Company",
                minWidth: 200,
                flex: 1,
              },
              {
                field: "location_name",
                headerName: "Location",
                minWidth: 170,
                flex: 1,
              },
              {
                field: "start_date",
                headerName: "Start Date",
                headerAlign: "center",
                align: "center",
                minWidth: 280,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs(params.row.adjusted_start_date).format(
                        "MMM D, YYYY hh:mm a"
                      )}
                    </div>
                  );
                },
              },
              {
                field: "end_date",
                headerName: "End Date",
                headerAlign: "center",
                align: "center",
                minWidth: 280,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs(params.row.adjusted_end_date).format(
                        "MMM D, YYYY hh:mm a"
                      )}
                    </div>
                  );
                },
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
      {openDateModal && (
        <DateTimeModal
          handleDateTimeModal={() => handleDateTimeModal()}
          setStartDate={(e) => setStartDate(e)}
          setEndDate={(e) => setEndDate(e)}
          open={openDateModal}
          fetchSchedule={() => fetchSchedule()}
          selectedRadio={selectedRadio}
          setSelectedRadio={(e) => setSelectedRadio(e)}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      {openModel === true && (
        <Modal className="dashboard" open={openModel}>
          <div className="modal_block">
            <div className="modal_header">
              <div className="cancel-btn-wrap">
                <IoIosArrowBack
                  onClick={() => {
                    setOpenModel(false);
                  }}
                />
              </div>
              <div className="modal_title_wrapper">
                <h2 className="modal_title">Date Range</h2>
              </div>
              <div className="cancel-btn-wrap"></div>
            </div>
            <div className="modal_content">
              <div className="modal_content_inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <label>
                        From Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="start_date"
                          format="MM-DD-YYYY"
                          onChange={(newStartDate) => {
                            setStartDateCopy(
                              dayjs(newStartDate).format("MM-DD-YYYY")
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <label>
                        To Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="end_date"
                          format="MM-DD-YYYY"
                          onChange={(newEndDate) => {
                            setEndDateCopy(
                              dayjs(newEndDate).format("MM-DD-YYYY")
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal_footer">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  handleSubmit(e);
                  setOpenModel(false);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ScheduleList;
