import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import "../MilestoneView/View.scss";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import { RxCross1 } from "react-icons/rx";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import AddEditMilestone from "../../../components/Milestone/AddEditMilestone";
import DeleteModal from "../../../components/Model/DeleteModal";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../../components/FileInput/FileDisplay";

const MilestoneView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const { id } = params || {};
  const [openModal, setOpenModal] = useState(false);
  const [DeleteMilestone, SetDeleteMilestone] = useState(false);
  const [rowsData, setRowsData] = useState();
  const [deleteImg, setDeleteImg] = useState("");
  const [loading, setLoading] = useState(false);
  const decryptedId = Decryption(params?.id);
  const { company_id, location_id } = useFilter();

  const fetchMilestone = () => {
    setLoading(true);
    httpService
      .get(`${`milestone/get_milestone/${decryptedId}`}`)
      .then((response) => {
        setRowsData(response?.data[0]);
        setDeleteImg("");
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchMilestone();
  }, [params?.id, showLoginPopup]);

  const handleOpen = () => {
    setOpenModal(true);
    fetchMilestone();
  };

  const handleClose = () => {
    setOpenModal(false);
    setDeleteImg("");
    SetDeleteMilestone(false);
    fetchMilestone();
  };

  const HandleDeleteMilestone = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(
          `${"milestone/delete_milestone"}/${decryptedId}`
        );
        navigate("/milestone");
        if (response) {
          toast.success("Milestone Deleted successfully", {
            position: "top-right",
          });
          SetDeleteMilestone(false);
          fetchMilestone();
        }
      } catch (error) {
        SetDeleteMilestone(false);
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...rowsData?.attached]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in rowsData) {
      if (key === "attached") {
        for (let i = 0; i < newArray.length; i++) {
          formData.append(`existFile[${i}]`, newArray[i]);
        }
      } else {
        if (rowsData[key] !== null) {
          const value =
            key === "sort_order" ? rowsData[key] || "" : rowsData[key];
          formData.append(key, value);
        }
      }
    }
    try {
      let response = await httpService.put(
        `${"milestone/edit_milestone"}/${decryptedId}`,
        formData
      );
      if (response.message) {
        SetDeleteMilestone(false);
        fetchMilestone();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      SetDeleteMilestone(false);
      // toast.error(error, {
      //   position: "top-right",
      // });
    }
  };

  const duration = displayDuration(
    rowsData?.days,
    rowsData?.hours,
    rowsData?.minutes
  );

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="milestone-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/milestone")} />
              </span>
              Milestone Details - [{rowsData?.name ? rowsData?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              onClick={() => handleOpen()}
              type="submit"
              className="btn btn-edit"
            >
              <CiEdit /> Edit{" "}
            </button>

            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                SetDeleteMilestone(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.name ? rowsData?.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Duration </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{duration ? duration : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s) </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.version ? rowsData?.version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Company </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {rowsData?.company_name ? rowsData?.company_name : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Location </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {rowsData?.location_name ? rowsData?.location_name : "--"}
                  </p>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Activities</label>
                    <span>:</span>
                  </div>
                </div>

                <div className="col-md-10 mb-3">
                  <div className={`milestone-list-wrap`}>
                    {rowsData?.activities && rowsData?.activities.length > 0 ? (
                      rowsData?.activities.map((activity, index) => (
                        <>
                          <div className="milestone-md-list-wrap  mb-2">
                            <div key={index}>
                              <span>
                                {activity.activity_name
                                  ? activity.activity_name
                                  : "--"}
                              </span>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div>--</div>
                    )}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Tasks</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className={`milestone-list-wrap`}>
                    {rowsData?.activities && rowsData?.activities.length > 0 ? (
                      rowsData?.activities.map((activity, index) => (
                        <>
                          {activity.tasks && activity.tasks.length > 0 ? (
                            activity.tasks.map((task, taskIndex) => (
                              <div key={taskIndex}>
                                <span>
                                  {task.task_name ? task.task_name : ""}
                                </span>
                              </div>
                            ))
                          ) : (
                            <div key={index}>--</div>
                          )}
                        </>
                      ))
                    ) : (
                      <div>--</div>
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {rowsData?.description ? rowsData?.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={rowsData?.attached}
                  handleDelete={() => SetDeleteMilestone(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <AddEditMilestone
          open={openModal}
          handleClose={() => handleClose()}
          milestone_id={decryptedId}
          isAdd={false}
          fetchMilestone={() => fetchMilestone()}
          companyId={company_id}
          locationId={location_id}
        />
      )}

      <DeleteModal
        open={DeleteMilestone}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeleteMilestone()}
      />
    </>
  );
};

export default MilestoneView;
