import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxPlus } from "react-icons/rx";
import "./Maintenance.scss";
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddEditMaintainence from "../../../components/Maintainance/AddForm/AddEditMaintainence";
import DeleteModal from "../../../components/Model/DeleteModal";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";

const Maintenance = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [maintainence, setMaintainence] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [clickedmaintenance, setClickedmaintenance] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [DeleteMaintenencae, setDeleteMaintenencae] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const { company_id, location_id } = useFilter();

  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchMaintenance = () => {
    setLoading(true);
    // const payload = {
    //   milestone_id: milestone_id,
    //   activity_ids: activity_id,
    // };
    httpService
      .get(
        `maintenance/get_maintenances?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
      )
      .then((response) => {
        setMaintainence(response?.data?.data);
        // setSearchData(response?.data);
        setRowCount(response?.data?.pagination?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchMaintenance();
  }, [
    company_id,
    location_id,
    showLoginPopup,
    page,
    pageSize,
    searchData,
    columnName,
    sortOrder,
  ]);

  const handleOpen = () => {
    setOpen(true);
    setIsAdd(true);
    setClickedmaintenance("");
  };
  const handleClose = () => {
    setOpen(false);
    setIsAdd(false);
    setClickedmaintenance("");
    setOpenDelete(false);
    fetchMaintenance();
  };

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  // const searchMaintenance = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     maintainence?.length > 0 &&
  //     maintainence?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const HandleDeleteMaintenence = async () => {
    if (DeleteMaintenencae) {
      try {
        const response = await httpService.delete(
          `${"maintenance/delete_maintenance"}/${DeleteMaintenencae}`
        );
        if (response) {
          fetchMaintenance();
          navigate("/maintenance");
          toast.success("Maintenance Deleted successfully", {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenDelete(false);
        fetchMaintenance();
      } catch (error) {
        setOpenDelete(false);
        console.error(
          "Error deleting maintenance:",
          error?.response?.data?.error
        );
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="maintenance">
        <div className="page-title">
          <div className="title-wrap">
            <h1>Maintenance</h1>
          </div>{" "}
          <div className="right_part">
            {/* <DropDownFilter
              locationId={locationId}
              milestone_id={milestone_id}
              setMilestone_id={(e) => setMilestone_id(e)}
              activity_id={activity_id}
              setActivity_id={(e) => setActivity_id(e)}
            /> */}
            <div className="button-wrap">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => handleOpen()}
              >
                <RxPlus /> Add Maintenance{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="table-filter-wrap">
          <div className="header_search-wrap">
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button className="btn search-btn search_btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchData(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${maintainence?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              maintainence?.length > 0 &&
              maintainence?.map((maintainence, index) => ({
                ...maintainence,
                id: maintainence.id,
                sequenceNumber: index + 1,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "name",
                headerName: "Maintenance Name",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      <span>{params.row.name}</span>
                    </div>
                  );
                },
              },
              {
                field: "duration",
                headerName: "Duration",
                width: 300,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  // console.log(params.row);
                  const duration = displayDuration(
                    params.row.days,
                    params.row.hours,
                    params.row.minutes
                  );
                  return <div>{duration}</div>;
                },
              },
              {
                field: "milestones",
                headerName: "Milestone",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { milestones } = params.row;
                  const milestone_name = milestones
                    ?.map((item) => item.name)
                    ?.join(", ");
                  return (
                    <div className="employee-name-weap">
                      <span>{milestone_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "activities",
                headerName: "Activity",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { activities } = params.row;
                  const act_name = activities
                    ?.map((item) => item.name)
                    ?.join(", ");
                  return (
                    <div className="employee-name-weap">
                      <span>{act_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "company_name",
                headerName: "Company",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { company_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{company_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                flex: 1,
                minWidth: 200,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const { location_name } = params.row;
                  return (
                    <div className="employee-name-weap">
                      <span>{location_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 100,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e), setDataId(params.row.id);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // console.log(params.row.id);
                            viewPage(dataId);
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setIsAdd(false);
                            setClickedmaintenance(dataId);
                            setOpen(true);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>{" "}
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteMaintenencae(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Delete</div>{" "}
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>

      {open === true && (
        <AddEditMaintainence
          open={open}
          isAdd={isAdd}
          clickedmaintenance={clickedmaintenance}
          handleClose={() => handleClose()}
          fetchMaintenance={() => fetchMaintenance()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      <DeleteModal
        open={openDelete}
        HandleClose={() => handleClose()}
        HandleDelete={() => {
          HandleDeleteMaintenence(), setClickedmaintenance("");
        }}
      />
    </>
  );
};

export default Maintenance;
