import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import "../CompanyView/View.scss";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import AddEditCompany from "../../../components/Company/AddEditCompany";
import DeleteModal from "../../../components/Model/DeleteModal";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import { RxCross1 } from "react-icons/rx";
import FileDisplay from "../../../components/FileInput/FileDisplay";

const CompanyView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();

  //for edit modal
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  //delete company modal
  const [openDelete, setDeleteModal] = useState(false);
  const [rowsData, setRowsData] = useState();
  const [deleteImg, setDeleteImg] = useState("");

  useEffect(() => {
    fetchCompany();
  }, [params?.id, showLoginPopup]);

  const decryptedId = Decryption(params?.id);

  const fetchCompany = () => {
    setLoading(true);
    httpService
      .get(`company/get_company/${decryptedId}`)
      .then((response) => {
        setRowsData(response?.data[0]);
        setDeleteImg("");
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const {
    id,
    company_name,
    company_size,
    description,
    attached,
    sort_order,
    version,
  } = rowsData || {};

  const handleEdit = () => {
    setOpenModal(true);
  };

  const naviagteBack = () => {
    navigate("/company");
  };

  const handleDelete = () => {
    setDeleteImg("");
    setDeleteModal(true);
  };

  const HandleDeleteCMP = async () => {
    if (deleteImg !== "") {
      handleDeleteImg(deleteImg);
    } else {
      try {
        const response = await httpService.delete(
          `${"company/delete_company"}/${id}`
        );
        setOpenModal(false);
        if (response) {
          fetchCompany();
          navigate("/company");
          toast.success(response?.message, {
            position: "top-right",
          });
        }
      } catch (error) {
        setOpenModal(false);
        console.error("Error deleting company:", error?.response?.data?.error);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...attached];
    newArray.splice(imgId, 1);

    const formData = new FormData();
    formData.append("company_name", company_name);
    formData.append("company_size", company_size);
    formData.append("description", description);
    formData.append("sort_order", sort_order || "");
    formData.append("version", version || "");
    if (newArray?.length > 0) {
      for (let index = 0; index < newArray.length; index++) {
        formData.append(`existFile[${index}]`, newArray[index]);
      }
    } else {
      formData.append("existFile", []);
    }
    try {
      const response = await httpService.put(
        `${"company/edit_company"}/${decryptedId}`,
        formData
      );
      if (response) {
        handleDelete();
        fetchCompany();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
      setDeleteModal(false);
    } catch (error) {
      setDeleteModal(false);
      console.error("Error deleting files:", error);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="company-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={naviagteBack} />
              </span>
              Company Details - [{company_name}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button onClick={handleEdit} type="submit" className="btn btn-edit">
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={handleDelete}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{company_name}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Size </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{company_size}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s) </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">{version ? version : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {description ? description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={attached}
                  handleDelete={() => handleDelete()}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AddEditCompany
          open={openModal}
          setOpen={(e) => setOpenModal(e)}
          rows={rowsData}
          fetchCompany={() => fetchCompany()}
          id={id}
          editData={rowsData}
        />
      )}
      {openDelete && (
        <DeleteModal
          open={openDelete}
          HandleClose={(e) => setDeleteModal(e)}
          id={deleteImg}
          HandleDelete={() => HandleDeleteCMP()}
        />
      )}
    </>
  );
};

export default CompanyView;
