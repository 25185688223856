import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/frosted-farms.svg";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { validate_email, validate_password } from "./Component/Validations";
import { FormHelperText } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useFilter } from "../../context/FilterProvider";

const Login = () => {
  //should not go to login page if logged in
  useEffect(() => {
    if (localStorage.getItem("Login Token")) {
      navigate("/");
    }
  });

  const { setCompany_id, setLocation_id } = useFilter();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [clicked, setClicked] = useState(false);
  const [clickedd, setClickedd] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //error displaying
  const [erroremail, setEmailError] = useState("");
  const [errorpassword, setPasswordError] = useState("");

  const setValueOnLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const setRoleWiseDatas = (user) => {
    if (user?.role_id === 1) {
      setValueOnLocalStorage("companyId", 0);
      setValueOnLocalStorage("locationId", 0);
      setCompany_id(0);
      setLocation_id(0);
    } else if (user?.role_id === 2) {
      setValueOnLocalStorage("companyId", user?.company_id);
      setValueOnLocalStorage("locationId", 0);
      setCompany_id(user?.company_id);
      setLocation_id(0);
    } else if (user?.role_id === 3) {
      setValueOnLocalStorage("companyId", user?.company_id);
      setValueOnLocalStorage("locationId", user?.location_id);
      setCompany_id(user?.company_id);
      setLocation_id(user?.location_id);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const device_token = localStorage.getItem("device_token");
    //Validations
    const emailError = validate_email(email);
    const passwordError = validate_password(password);

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }
    if (emailError) {
      setEmailError(emailError);
      return;
    }
    if (passwordError) {
      setPasswordError(passwordError);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL + "user/login"}`,
        {
          email: email.trim(),
          password: password.trim(),
          device_token: device_token,
        }
      );
      if (response?.status === 200) {
        toast.success("Logged In Successfully", {
          position: "top-right",
        });
        const userData = response?.data?.data?.user;
        localStorage.setItem("Login Token", userData.token);
        localStorage.setItem("email", email);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.user)
        );
        setRoleWiseDatas(userData);
        navigate("/");
      }
    } catch (error) {
      console.error("Error while login", error);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
      });
      if (error?.response?.status === 401) {
        // console.log("Invalid Email Address");
        setEmailError("Invalid Email Address");
      }
      if (error?.response?.status === 400) {
        setPasswordError("Invalid Password");
      }
    }
  };
  return (
    <>
      <div className="auth_screen_main_wrapper">
        <div className="row">
          <div className="auth-left col-sm-12 col-md-6">
            <div className="auth-img">
              <div className="auth-info">
                <h1>Get Everything you want</h1>
                <p className="auth-disc">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="login_inner">
              <div className="logo_wrap">
                <img src={Logo} alt="frosted farms" />
              </div>
              <div className="form_wrapper">
                <div className="login_form">
                  <div className="login_form_inner">
                    <form>
                      <div className="title_wrapper">
                        <h1 className="login_title">Welcome Back</h1>
                        <div className="desc">
                          Enter your credentials to access your account
                        </div>
                      </div>

                      <div
                        className={`form_field_wrapper ${
                          erroremail ? "error" : ""
                        }`}
                      >
                        <label
                          style={{ color: erroremail ? "red" : "inherit" }}
                        >
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          // id="input-with-icon-textfield"
                          style={{
                            border: erroremail
                              ? "1px solid red"
                              : clicked
                              ? "1px solid blue"
                              : "inherit",
                          }}
                          type="text"
                          placeholder="Enter your Email"
                          variant="outlined"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onClick={() => {
                            setClicked(true);
                            setEmailError("");
                          }}
                          onBlur={() => setClicked(false)}
                        />
                        {erroremail && (
                          <FormHelperText id="component-error-text">
                            {/* <RiErrorWarningLine /> */}
                            {erroremail}
                          </FormHelperText>
                        )}
                      </div>

                      <div className="form_field_wrapper">
                        <label
                          style={{ color: errorpassword ? "red" : "inherit" }}
                        >
                          Password<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          // id="input-with-icon-textfield"
                          style={{
                            border: errorpassword
                              ? "1px solid red"
                              : clickedd
                              ? "1px solid blue"
                              : "inherit",
                          }}
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Enter your Password"
                          variant="outlined"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onClick={() => {
                            setClickedd(true);
                            setPasswordError("");
                          }}
                          onBlur={() => setClickedd(false)}
                        />
                        {errorpassword && (
                          <FormHelperText id="component-error-text">
                            {/* <RiErrorWarningLine /> */}
                            {errorpassword}
                          </FormHelperText>
                        )}
                      </div>

                      <div className="form_field_wrapper forgotpass">
                        <Link to={"/forgot-pass"} className="ato">
                          Forgot Password?
                        </Link>
                      </div>

                      <button
                        onClick={handleLogin}
                        type="submit"
                        className="btn btn-primary"
                      >
                        {" "}
                        Sign In
                      </button>
                      {/* <div className="form_field_wrapper signuplink_block">
                                                <Link className="ato signupa" href="/signup">Don't have an account? &nbsp;<span className="signup_txt">Register Now</span></Link>
                                            </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
