import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/frosted-farms.svg";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";
import axios from "axios";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [clicked, setClicked] = useState(false);
  const [clickedd, setClickedd] = useState(false);

  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [textsuccess, setTextSuccess] = useState("");
  const [texterror, setTextError] = useState("");

  //error validations
  const [passworderror, setPasswordError] = useState("");
  const [confirmpassworderror, setConfirmPasswordError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    //validations
    if (!newPassword && !confirmPassword) {
      setPasswordError("Password field empty");
      setConfirmPasswordError("Password field empty");
      return;
    }
    if (newPassword.length < 8) {
      setPasswordError("Password length must be greater than 8 ");
      return;
    }
    if (
      !/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])/.test(newPassword)
    ) {
      setPasswordError(
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
      );
      return;
    }
    const email = localStorage.getItem("Email Id");
    const resetToken = localStorage.getItem("Reset-Token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL + "user/reset_password"}`,
        {
          email,
          newPassword: newPassword.trim(),
          confirmPassword: confirmPassword.trim(),
          resetToken,
        }
      );
      // console.log(response.status);
      if (response) {
        setTextSuccess("Password Reset Successfully, Sign-In Now");
        toast.success("Password Reset Successfully, Sign-In Now", {
          position: "top-right",
        });
        localStorage.clear();
        setPasswordError("");
        navigate("/login");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      setPasswordError(errorMessage);
    }
  };

  return (
    <>
      <div className="auth_screen_main_wrapper">
        <div className="row">
          <div className="auth-left col-sm-12 col-md-6">
            <div className="auth-img">
              <div className="auth-info">
                <h1>Get Everything you want</h1>
                <p className="auth-disc">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="login_inner">
              <div className="logo_wrap">
                <img src={Logo} alt="frosted farms" />
              </div>
              <div className="form_wrapper">
                <div className="login_form">
                  <div className="login_form_inner">
                    <form>
                      <div className="title_wrapper">
                        <h1 className="login_title">Change Password</h1>
                        <div className="desc">
                          In order to <strong>protect your account</strong>,
                          make sure your password is strong
                        </div>
                      </div>

                      <div>
                        <h5
                          style={{
                            color: textsuccess
                              ? "green"
                              : texterror
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {textsuccess || texterror || ""}{" "}
                          {/* Display success or error message */}
                        </h5>
                      </div>

                      <div className="form_field_wrapper">
                        <label
                          style={{ color: passworderror ? "red" : "inherit" }}
                        >
                          Password<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          id="input-with-icon-textfield"
                          type={showPassword ? "text" : "password"}
                          style={{
                            border: passworderror
                              ? "1px solid red"
                              : clicked
                              ? "1px solid blue"
                              : "inherit",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Password"
                          variant="outlined"
                          value={newPassword}
                          onChange={(e) => setPassword(e.target.value)}
                          onClick={() => setClicked(true)}
                          onBlur={() => setClicked(false)}
                        />
                        {passworderror && (
                          <FormHelperText id="component-error-text">
                            <RiErrorWarningLine /> {passworderror}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="form_field_wrapper">
                        <label
                          style={{
                            color: confirmpassworderror ? "red" : "inherit",
                          }}
                        >
                          Confirm Password
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          id="input-with-icon-textfield"
                          type={showPassword ? "text" : "password"}
                          style={{
                            border: confirmpassworderror
                              ? "1px solid red"
                              : clickedd
                              ? "1px solid blue"
                              : "inherit",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Password"
                          variant="outlined"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          onClick={() => setClickedd(true)}
                          onBlur={() => setClickedd(false)}
                        />

                        {confirmpassworderror && (
                          <FormHelperText id="component-error-text">
                            <RiErrorWarningLine /> {confirmpassworderror}
                          </FormHelperText>
                        )}
                      </div>

                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Reset Password
                      </button>
                      <div className="form_field_wrapper signuplink_block">
                        <Link to={"/login"} className="ato signupa" href="/">
                          Back to &nbsp;
                          <span className="signup_txt">Sign In</span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
