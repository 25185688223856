import "./location-custom.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxPlus } from "react-icons/rx";
import { DataGrid, gridClasses, GridMoreVertIcon } from "@mui/x-data-grid";
import { Box, styled, IconButton, MenuItem, Popover } from "@mui/material";
import { HiOutlineEye } from "react-icons/hi2";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import AddEditLocation from "../../components/Location/Form/AddEditLocation";
import DeleteModal from "../../components/Model/DeleteModal";
import { toast } from "react-toastify";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import { Encryption } from "../../Mixin/EncryptDecryptId";
import { useFilter } from "../../context/FilterProvider";

const Location = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [editData, SetEditData] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const { company_id, location_id } = useFilter();

  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");

  useEffect(() => {
    fetchLocation();
  }, [
    company_id,
    location_id,
    showLoginPopup,
    page,
    pageSize,
    searchData,
    columnName,
    sortOrder,
  ]);

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  // const searchLocation = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     rows?.length > 0 &&
  //     rows?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const HandleDeleteEMP = async () => {
    try {
      await httpService.delete(`location/delete_location/${dataId}`);
      // navigate("location");
      toast.success("Location Deleted successfully", {
        position: "top-right",
      });
      setOpenModel(false);
      fetchLocation();
    } catch (error) {
      setOpenModel(false);
      console.error("Error deleting location:", error);
      toast.error(error, {
        position: "top-right",
      });
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const fetchLocation = async () => {
    setLoading(true);
    await httpService
      .get(
        `location/get_location?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
      )
      .then((response) => {
        setRows(response?.data?.data); // Assuming the response data is an array of rows
        // setSearchData(response?.data);
        setRowCount(response?.data?.pagination?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  // return (
  //   <div>
  //     <h1 style={{ color: "blue", fontFamily: "wf_title" }}>Coming soon!!</h1>
  //   </div>
  // );

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  return (
    <>
      <div className="location">
        {loading && <FullScreenLoader />}
        <div className="page-title">
          <div className="title-wrap">
            <h1>Locations</h1>
          </div>
          <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                setIsAdd(false);
                setOpen(true);
              }}
            >
              <RxPlus /> Add Location{" "}
            </button>
          </div>
        </div>
        <div className="table-filter-wrap">
          <div>
            <div className="header_search_bar common_content">
              <div className="input-group input_group">
                <button className="btn search-btn search_btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <circle
                      cx="9.98856"
                      cy="9.98856"
                      r="8.98856"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2402 16.7071L19.7643 20.222"
                      stroke="#495057"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <input
                  type="search"
                  className="form-control form_control"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchData(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${rows?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            className="location-table"
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              rows?.length > 0 &&
              rows?.map((row, index) => ({
                ...row,
                id: row.id,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "name",
                headerName: "Location Name",
                flex: 1,
                minWidth: 200,
                maxWidth: 400,
              },
              {
                field: "company_name",
                headerName: "Company",
                flex: 1,
                minWidth: 200,
                maxWidth: 400,
              },
              {
                field: "description",
                headerName: "Description",
                flex: 1,
                minWidth: 300,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                  <div className="employee-name-weap">
                    <span>{params.value || "--"}</span>
                  </div>
                ),
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 100,
                disableColumnMenu: true,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e),
                            setDataId(params.row.id),
                            SetEditData(params.row);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => viewPage(dataId)}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpen(true);
                            setIsAdd(true);
                            setId(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>{" "}
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenModel(true);
                            // setDeleteUser(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Delete</div>{" "}
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
                sortable: false,
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>
      <AddEditLocation
        open={open}
        setOpen={(e) => {
          setOpen(e);
          SetEditData("");
          setId("");
        }}
        id={id}
        editData={editData}
        fetchLocation={() => fetchLocation()}
        isAdd={isAdd}
        companyId={company_id}
      />

      <DeleteModal
        open={openModel}
        HandleClose={(e) => {
          setOpenModel(e), SetEditData("");
        }}
        HandleDelete={() => {
          HandleDeleteEMP(), SetEditData("");
        }}
      />
    </>
  );
};

export default Location;
