import {
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { useEffect, useState, version } from "react";
import { HttpService } from "../../service/HttpService";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import FileInput from "../FileInput/FileInput";
import { useAuth } from "../../context/AuthProvider";
import SelectInput from "../SelectInput/SelectInput";
import { PiUploadSimpleThin } from "react-icons/pi";

const AddEditInventory = ({
  open,
  isAdd,
  fetchInventory,
  inventory_id,
  handleClose,
  companyId,
  locationId,
}) => {
  const [locationNames, setLocationNames] = useState([]);
  const [roomNames, setRoomNames] = useState([]);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  useEffect(() => {
    fetchLocations();
  }, [companyId, locationId, showLoginPopup]);

  const fetchLocations = () => {
    httpService
      .get("location/get_location")
      .then((response) => {
        setLocationNames(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    // if (inventory_id !== "") {
    //   httpService
    //     .get(`inventory/get_inventory/${inventory_id}`)
    //     .then((response) => {
    //       formik.setValues({
    //         name: response?.data[0].name,
    //         location:
    //           (response?.data[0]?.location_id?.length > 0 &&
    //             response?.data[0]?.location_id.map((item) => item.id)) ||
    //           [],
    //         room:
    //           (response?.data[0]?.room_id?.length > 0 &&
    //             response?.data[0]?.room_id.map((item) => item.id)) ||
    //           [],
    //         category: response?.data[0].category || "",
    //         quantity: response?.data[0].quantity || "",
    //         description: response?.data[0].description,
    //         attached: response?.data[0].attached || [],
    //         profile_image: response?.data[0].profile_image || "",
    //       });
    //       if (response) {
    //         if (response?.data[0].location_id) {
    //           getRoomsByLocations(
    //             response?.data[0].location_id?.map((item) => item.id)
    //           );
    //         }
    //       }
    //     });
    // } else {
    //   formik.resetForm();
    // }
  }, [inventory_id]);

  const handleDropDownChange = (key, id) => {
    if (key === "location") {
      // Call the API to fetch location data based on the selected company_id
      getRoomsByLocations(id);
    }
  };

  const getRoomsByLocations = (id) => {
    return httpService
      .post(`rooms/get_rooms/location`, { location_id: id })
      .then((response) => {
        setRoomNames(response?.data);
        if (response?.data.length === 0) {
          formik.setFieldValue("room", []);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleCloseSave = () => {
    formik.resetForm();
    handleClose();
    setRoomNames([]);
  };

  const category = [
    { id: 1, name: "Biodynamic Farming" },
    { id: 2, name: "Agroforestry" },
    { id: 3, name: "Hydroponics" },
    { id: 4, name: "Companion Planting" },
    { id: 5, name: "Integrated Pest Management (IPM)" },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      location: [],
      room: [],
      quantity: "",
      description: "",
      version: "",
      profile_image: "",
      file: [],
      attached: [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please Enter Inventory Name")
        .max(200, "Inventory Name must be less than 200 characters"),
      category: Yup.string().required("Please Select Category"),
      location: Yup.array()
        .min(1, "Please Select Location")
        .required("Please Select Location"),
      room: Yup.array()
        .min(1, "Please Select Room")
        .required("Please Select Room"),
      quantity: Yup.string().required("Please Enter Quantity"),
      description: Yup.string().max(
        2000,
        "Description must be less than 2000 characters"
      ),
      profile_image: Yup.mixed()
        // .required("Profile Image Is Required")
        .test(
          "fileSize",
          "Profile image size must be less than 10MB",
          (value) => {
            return !value || (value && value.size <= 10485760); // 10MB in bytes
          }
        )
        .test(
          "fileFormat",
          "Profile image must be in jpg, png, jpeg, gif, or webp format",
          (value) => {
            return (
              !value ||
              (value &&
                [
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/gif",
                  "image/webp",
                ].includes(value.type))
            );
          }
        ),
      file: Yup.mixed()
        // .required("Attachment Is Required")
        .test("fileSize", "Attachment size must be less than 10MB", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          return files.every((file) => file.size <= 10485760); // Check size of each file
        })
        .test("fileFormat", "Invalid file format", (value) => {
          if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
          const files = Object.values(value); // Convert object values to an array of files
          if (files.length === 0) return true; // Skip if no files provided
          const allowedExtensions = {
            video: ["mp4", "webm", "mov"],
            pdf: ["pdf"],
            document: ["doc", "docx"],
            spreadsheet: ["xls", "xlsx", "csv"],
            image: ["jpg", "png", "jpeg", "gif", "webp"],
          };
          return files.every((file) => {
            if (!file || !file.name) return false; // If file or file name is undefined, return false
            const fileExtension = file?.name?.split(".").pop().toLowerCase();
            const fileType = Object.keys(allowedExtensions).find((type) =>
              allowedExtensions[type].includes(fileExtension)
            );
            return !!fileType;
          });
        }),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("category", values.category);
      if (values.location?.length > 0) {
        values?.location?.forEach((item, index) => {
          formData.append(`location_id[${index}]`, item);
        });
      }
      if (values?.room.length > 0) {
        values?.room?.forEach((item, index) => {
          formData.append(`room_id[${index}]`, item);
        });
      }
      formData.append("quantity", values.quantity);
      formData.append("description", values.description || "");
      formData.append("version", values.version || "");
      formData.append("profile_image", values.profile_image || "");

      // Check if new files are provided
      if (values.file && values.file.length > 0) {
        for (let i = 0; i < values.file.length; i++) {
          formData.append(`attached`, values.file[i]);
        }
      } else {
        formData.append("attached", []);
      }
      if (values.attached) {
        if (activity_id !== "" && !isAdd) {
          for (let i = 0; i < values.attached.length; i++) {
            formData.append(`existFile[${i}]`, values.attached[i]);
          }
        }
      }

      try {
        if (isAdd) {
          const response = await httpService.post(
            "inventory/add_inventory",
            formData
          );
          if (response) {
            toast.success("Inventory Added Successfully", {
              position: "top-right",
            });
            formik.resetForm();
            handleClose();
            fetchInventory();
          }
        } else {
          const res = await httpService.put(
            `${"inventory/edit_inventory"}/${inventory_id}`,
            formData
          );
          if (res) {
            toast.success("Inventory Updated Successfully", {
              position: "top-right",
            });
            handleClose();
            fetchInventory();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal
      className="task-modal"
      open={open}
      onClose={() => {
        handleCloseSave();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">
                {isAdd === true ? "Add Inventory" : "Edit Inventory"}
              </h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1
                onClick={() => {
                  handleCloseSave();
                }}
              />
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="item_increase">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form_field_wrapper">
                      <label>
                        Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        id="name"
                        InputProps={{}}
                        placeholder="Enter Inventory Name"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: "red" }}>{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <SelectInput
                      label="Category"
                      name="category"
                      value={formik.values.category}
                      options={category}
                      multiple={false}
                      onChange={(event) => {
                        formik.setFieldValue("category", event.target.value);
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.category && formik.errors.category}
                      required={true}
                      displayEmpty
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectInput
                      label="Location"
                      name="location"
                      value={formik.values.location}
                      options={locationNames}
                      multiple={true}
                      selectAll={true}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value[value.length - 1] === "all") {
                          const selectedIds =
                            formik.values?.location?.length ===
                            locationNames.length
                              ? []
                              : locationNames.map((item) => item.id);
                          formik.setFieldValue("location", selectedIds);
                          handleDropDownChange("location", selectedIds);
                          return;
                        }
                        formik.setFieldValue("location", event.target.value);
                        handleDropDownChange("location", event.target.value);
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.location && formik.errors.location}
                      required={true}
                      displayEmpty
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectInput
                      label="Room"
                      name="room"
                      value={formik.values.room}
                      options={roomNames}
                      multiple={false}
                      onChange={(event) => {
                        formik.setFieldValue("room", event.target.value);
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.room && formik.errors.room}
                      required={true}
                      displayEmpty
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="form_field_wrapper">
                      <label>
                        Total Quantity<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="quantity"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        placeholder="Enter Quantity"
                        value={formik.values.quantity}
                        onChange={(event) => {
                          formik.setFieldValue("quantity", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.quantity}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_field_wrapper">
                      <label>Reff Doc. Version(s)</label>
                      <TextField
                        name="version"
                        type="text"
                        placeholder="Enter Version"
                        value={formik.values.version}
                        onChange={(event) => {
                          formik.setFieldValue("version", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <label>Description</label>
                      <div>
                        <TextareaAutosize
                          id="description"
                          className="w-100 text-area"
                          aria-label="Type Description"
                          minRows={3}
                          placeholder="Type Description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form_field_wrapper">
                      <label>Profile Image</label>
                      <div className="upload-btn-wrap">
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<PiUploadSimpleThin />}
                        >
                          <input
                            className="file-type"
                            type="file"
                            accept="image/*"
                            name="profile_image"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "profile_image",
                                e.currentTarget.files[0]
                              );
                            }}
                            handleBlur={formik.handleBlur}
                          />
                          {formik.values.profile_image ? (
                            <span>{formik.values.profile_image?.name}</span> // Displaying the name of the image
                          ) : (
                            <span>Upload Profile Image</span>
                          )}
                        </Button>
                      </div>
                      {formik.errors.profile_image &&
                        formik.touched.profile_image && (
                          <span style={{ color: "red" }}>
                            {formik.errors.profile_image}
                          </span>
                        )}
                    </div>
                  </div>

                  <FileInput
                    file={formik.values.file}
                    attachment={formik.values.attached}
                    handleBlur={formik.handleBlur}
                    name="file"
                    name2="attached"
                    setFieldValue={(fieldName, value) =>
                      formik.setFieldValue(fieldName, value)
                    }
                  />
                  {formik.errors.file && formik.touched.file && (
                    <span style={{ color: "red" }}>{formik.errors.file}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <button
              onClick={() => {
                handleCloseSave();
              }}
              className="btn btn-border"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {isAdd === true ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddEditInventory;
