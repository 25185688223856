export const validate_email = (email) => {
  if (!email.trim()) {
    return "Enter your email";
  }
  return "";
};

export const validate_password = (password) => {
  if (!password) {
    return "Enter your password";
  }
  return "";
};
