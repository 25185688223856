import React, { createContext, useState, useContext } from "react";
import { HttpService } from "../service/HttpService";
const httpService = new HttpService();
const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const companyId = localStorage.getItem("companyId");
  const locationId = localStorage.getItem("locationId");
  const [location_id, setLocation_id] = useState(locationId || 0);
  const [company_id, setCompany_id] = useState(companyId || 0);

  const [locationData, setLocationData] = useState([]);

  const setValueOnLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const handleCompany = (id) => {
    getLocationByCompany(id);
    setCompany_id(id);
    setValueOnLocalStorage("companyId", id);
    setValueOnLocalStorage("locationId", 0);
    setLocation_id(0);
  };

  const getLocationByCompany = async (id) => {
    try {
      const response = await httpService.get(
        `location/get_location/company/${id}`
      );
      setLocationData(response?.data);
      return response;
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const handleLocation = (id) => {
    setValueOnLocalStorage("locationId", id);
    setLocation_id(id);
  };
  return (
    <FilterContext.Provider
      value={{
        company_id,
        location_id,
        setCompany_id,
        setLocation_id,
        handleCompany,
        handleLocation,
        locationData,
        getLocationByCompany,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
