// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFBDt2T33b4pJa1ePGT-ApkCiD0y1YPyM",
  authDomain: "frosted-farms.firebaseapp.com",
  projectId: "frosted-farms",
  storageBucket: "frosted-farms.appspot.com",
  messagingSenderId: "504226907157",
  appId: "1:504226907157:web:3ea4939240c01a43057a1c",
  measurementId: "G-CDTGTGWZX7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let messaging;
let currentToken = "";
export const requestPermission = async () => {
  console.log("Requesting user permission...");
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted" && currentToken === "") {
      console.log("Notification permission granted.");
      currentToken = await getToken(messaging, {
        vapidKey:
          "BBt-5RlO2OE5NMBf79EqXoNibUPnBXJaTD2lCTBnHa0H5inXGx0dqcd0-_zKhhl8mICZfDPdKaBCTV_lI4ne2NI",
      });
      if (currentToken) {
        console.log("Client Token:", currentToken);
        localStorage.setItem("device_token", currentToken);
      } else {
        console.log("Failed to generate registration token.");
      }
    } else {
      console.log("User permission denied.");
    }
  } catch (err) {
    console.log("Error while receiving the token:", err);
  }
};

if (await isSupported()) {
  messaging = getMessaging(app);
  requestPermission(); // Calling requestPermission after it's defined
} else {
  console.error("This browser does not support Firebase Cloud Messaging.");
}

// Define onMessageListener function
export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } else {
      console.error("This browser does not support Firebase Cloud Messaging.");
    }
  });

// Register the service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js", { scope: "/" })
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}
