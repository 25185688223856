import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddEditLocation from "../Form/AddEditLocation";
import DeleteModal from "../../Model/DeleteModal";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import { HttpService } from "../../../service/HttpService";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";

const ViewLocation = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [rowsData, setRowsData] = useState();
  const [deleteEmp, setDeleteEmp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { company_id, location_id } = useFilter();

  const decryptedId = Decryption(params?.id);

  const fetchLocation = async () => {
    setLoading(true);
    await httpService
      .get(`location/get_location/${decryptedId}`)
      .then((response) => {
        setRowsData(response.data[0]);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchLocation();
  }, [params?.id, showLoginPopup]);

  const handleNavigate = () => {
    navigate("/location");
  };

  const HandleDeleteEMP = async () => {
    setLoading(true);
    try {
      const res = await httpService.delete(
        `location/delete_location/${decryptedId}`
      );
      if (res) {
        navigate("/location");
        toast.success("Location Deleted successfully", {
          position: "top-right",
        });
        setDeleteEmp(false);
        fetchLocation();
      }
    } catch (error) {
      setLoading(false);
      setDeleteEmp(false);
      toast.error(error, {
        position: "top-right",
      });
    }
  };

  return (
    <>
      {loading ? (
        <FullScreenLoader />
      ) : (
        <div className="location-view ">
          <div className="page-title">
            <div className="title-wrap">
              <h1>
                <span className="back">
                  <BsArrowLeft onClick={handleNavigate} />
                </span>
                Location Details - [{rowsData?.name ? rowsData?.name : "--"}]
              </h1>
            </div>
            <div className="button-wrap view-responsive">
              <button
                type="submit"
                className="btn btn-edit"
                onClick={() => setOpen(true)}
              >
                <CiEdit /> Edit{" "}
              </button>
              <button
                type="submit"
                className="btn btn-delete"
                onClick={() => setDeleteEmp(true)}
              >
                <GoTrash /> Delete{" "}
              </button>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-2">
                    <div className="d-flex justify-content-md-between">
                      <label>Name </label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <p>{rowsData?.name ? rowsData?.name : "--"}</p>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex justify-content-md-between">
                      <label>Company </label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <p>
                      {rowsData?.company_name ? rowsData?.company_name : "--"}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex justify-content-md-between">
                      <label>Reff Doc. Version(s) </label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <p className="text-justify">
                      {rowsData?.version ? rowsData?.version : "--"}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <div className="d-flex justify-content-md-between">
                      <label>Description </label>
                      <span>:</span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <p className="text-justify">
                      {rowsData?.description ? rowsData?.description : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AddEditLocation
        open={open}
        setOpen={(e) => setOpen(e)}
        id={decryptedId}
        editData={rowsData}
        fetchLocation={() => fetchLocation()}
        isAdd={true}
        companyId={company_id}
        locationId={location_id}
      />

      <DeleteModal
        open={deleteEmp}
        HandleClose={(e) => setDeleteEmp(e)}
        HandleDelete={() => HandleDeleteEMP()}
      />
    </>
  );
};

export default ViewLocation;
