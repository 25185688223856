import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import "../ActivitiesView/View.scss";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import { RxCross1 } from "react-icons/rx";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import AddEditActivity from "../../../components/Activity/AddEditActivity";
import DeleteModal from "../../../components/Model/DeleteModal";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { toast } from "react-toastify";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../../components/FileInput/FileDisplay";

const ActivitiesView = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rowsData, setRowsData] = useState();
  const [deleteImg, setDeleteImg] = useState("");
  const [DeleteActivity, SetDeleteActivity] = useState(false);
  const decryptedId = Decryption(params?.id);
  const { company_id, location_id } = useFilter();

  const fetchActivity = () => {
    setLoading(true);
    httpService
      .get(`activity/get_activity/${decryptedId}`)
      .then((response) => {
        setRowsData(response?.data[0]);
        setDeleteImg("");
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchActivity();
  }, [params?.id, showLoginPopup]);

  const handleOpen = () => {
    setOpenModal(true);
    fetchActivity();
  };

  const handleClose = () => {
    setOpenModal(false);
    setDeleteImg("");
    SetDeleteActivity(false);
    fetchActivity();
  };

  const HandleDeleteActivity = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(
          `${"activity/delete_activity"}/${decryptedId}`
        );
        navigate("/activities");
        if (response) {
          toast.success("Activity Deleted successfully", {
            position: "top-right",
          });
          SetDeleteActivity(false);
          fetchActivity();
        }
      } catch (error) {
        SetDeleteActivity(false);
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...rowsData?.attached]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in rowsData) {
      if (key === "attached") {
        for (let i = 0; i < newArray.length; i++) {
          formData.append(`existFile[${i}]`, newArray[i]);
        }
      } else {
        if (rowsData[key] !== null) {
          const value =
            key === "sort_order" ? rowsData[key] || "" : rowsData[key];
          formData.append(key, value);
        }
      }
    }
    try {
      let response = await httpService.put(
        `${"activity/edit_activity"}/${decryptedId}`,
        formData
      );
      if (response.message) {
        SetDeleteActivity(false);
        fetchActivity();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      SetDeleteActivity(false);
    }
  };

  const duration = displayDuration(
    rowsData?.days,
    rowsData?.hours,
    rowsData?.minutes
  );

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="activities-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/activities")} />
              </span>
              Activities Details - [{rowsData?.name ? rowsData?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              onClick={() => handleOpen()}
              type="submit"
              className="btn btn-edit"
            >
              <CiEdit /> Edit{" "}
            </button>

            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => SetDeleteActivity(true)}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{rowsData?.name ? rowsData?.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Duration </label>
                    <span>:</span>
                  </div>
                </div>

                <div className="col-md-10">
                  <p>{duration ? duration : "--"}</p>
                </div>

                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Minimum Employee </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {rowsData?.min_employee ? rowsData?.min_employee : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Maximum Employee </label>
                    <span>:</span>
                  </div>
                </div>

                <div className="col-md-10">
                  <p>
                    {rowsData?.max_employee ? rowsData?.max_employee : "--"}
                  </p>
                </div>

                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span key={0}>
                      {rowsData?.version ? rowsData?.version : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Company</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span key={0}>
                      {rowsData?.company_name ? rowsData?.company_name : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Task</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {rowsData?.tasks && rowsData?.tasks?.length > 0 ? (
                      rowsData?.tasks?.map((task, index) => (
                        <span key={index}>
                          {task.task_name ? task.task_name : "--"}
                        </span>
                      ))
                    ) : (
                      <div>--</div>
                    )}
                  </div>
                </div>

                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Location</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span key={0}>
                      {rowsData?.location_name ? rowsData?.location_name : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {/* Use map instead of forEach */}
                    {/* {milestone_name > 0 &&
                      milestone_name?.map((milestone, index) => (
                        // Use key prop to provide a unique key for each child
                        <span key={index}>{milestone.name}</span>
                      ))} */}
                    <span key={0}>
                      {rowsData?.milestone_name
                        ? rowsData?.milestone_name
                        : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description: </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {rowsData?.description ? rowsData?.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={rowsData?.attached}
                  handleDelete={() => SetDeleteActivity(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AddEditActivity
          open={open}
          handleClose={() => handleClose()}
          activity_id={decryptedId}
          isAdd={false}
          fetchActivity={() => fetchActivity()}
          companyId={company_id}
          locationId={location_id}
        />
      )}

      <DeleteModal
        open={DeleteActivity}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeleteActivity()}
      />
    </>
  );
};

export default ActivitiesView;
