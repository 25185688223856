import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const checkAuthentication = () => {
    const token = localStorage.getItem("Login Token");
    const userData = localStorage.getItem("user");
    const expiryTime = userData && JSON.parse(userData)?.expiresIn;
    if (token && expiryTime) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (currentTime < parseInt(expiryTime)) {
        return true; // Token is valid
      } else {
        localStorage.clear();
        navigate("/login");
        return false;
      }
    }
    return false; // Token doesn't exist or has expired
  };

  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthentication());

  useEffect(() => {
    setIsAuthenticated(checkAuthentication());
  }, []); // Empty dependency array ensures it runs only once

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
